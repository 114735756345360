import React from 'react';
import { OwcTable, OwcTableCell, OwcTableHeader, OwcTableHeaderCell, OwcTableBody, 
         OwcTableRow, OwcTableGroup, OwcTypography, OwcIcon } from '@one/react';
import DelayedTooltip from '../general/DelayedTooltip.js';
import '../../styles.scss';

import {configData} from "../../config.js";
import {convertToDate, formatDate, fetchSigned} from "../../shared/Utilities.js"
import { renderToString } from 'react-dom/server'

/**
 * Renders the devices at location table
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class DevicesAtLocation extends React.Component {
  DEVICE_LIMIT = 101;  // limit includes the searched device
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      devicesLoaded: false,
      devices: [],
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deviceId !== this.props.deviceId 
      || prevProps.locationNo !== this.props.locationNo 
      || prevProps.systemTypeFilter !== this.props.systemTypeFilter) {
      if (this.props.deviceId !== null && this.props.locationNo !== null) {
        this.setState({ devicesLoaded: false, device: []}, () => this.loadDevices());
      } else {
        this.setState({
          devicesLoaded: true,
          devices: [],
        });
      }
    }
  }

  
  /**
   * Loads the devices at this location
   */
  loadDevices() {
    const locationNo = this.props.locationNo
    console.log("loading device at this location ", locationNo);

    const submissionData = {
      deviceId: this.props.deviceId,
      locationAccountNo: locationNo,
      systemTypeFilter: this.props.systemTypeFilter,
      limit: this.DEVICE_LIMIT,
    };


    fetchSigned(configData.DEVICE_DETAILS_API_URL + "devices-at-location/", {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submissionData)
    })
    .then(res => res.json())
    .then(
      (result) => {
        result.forEach(row=> {row.formattedAccounts = this.getAccountsText(row)});
        this.setState({ devices: result,  
                        devicesLoaded: true});
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  /**
   * Generates and returns the text to be used to describe the accounts related to this device
   */
  getAccountsText(item) {
    const messageList = [];
    if (item.shipToAccountNo !== null) {
      messageList.push("shipTo: " + item.shipToAccountNo);
    }
    if (item.soldToAccountNo !== null) {
      messageList.push("soldTo: " + item.soldToAccountNo);
    }
    if (item.billToAccountNo !== null) {
      messageList.push("billTo: " + item.billToAccountNo);
    }
    if (item.instrumentLocationAccountNo !== null) {
      messageList.push("Location: " + item.instrumentLocationAccountNo);
    }
    if (messageList.length > 0){
      return(
        <span>
          {messageList.map((message, index) =>(<pre  key={item.deviceId + index} style={{marginTop:"0",marginBottom:"0"}}>{message}</pre>))}
        </span>);
    } else {
      return(
        <span style={{overflowWrap:"normal"}}>
          None
        </span>
      );
    }

  }

  /**
   * Detemines the background colour for a row
   */
  applyColour(row, searchedRow, fieldKey, styleDict={}) {
    if (row === searchedRow)
    {
      styleDict.backgroundColor = "Lavender";
      
    } else {
      if ((searchedRow !== null) &&
          (searchedRow[fieldKey] !== null) &&
          (renderToString(row[fieldKey]) === renderToString(searchedRow[fieldKey]))) {
            styleDict.backgroundColor = configData.COLOUR_GREEN;
      }
    }
    return styleDict;
  }

  formatDateCellText(row) {
    if (row.minConnectedDate===null) {
      return (<></>);
    } else {
      return (
        <>
        {formatDate(row.minConnectedDate)}
        <br/>to<br/>
        {formatDate(row.maxConnectedDate)}
        </>
      )
    }
  }

  applyConnectedDateColour(row, searchedRow, styleDict={}) {
    if (row !== searchedRow) {
      if (this.doDatesOverlap(row, searchedRow)) {
        styleDict.backgroundColor = configData.COLOUR_EXCLUDED;
      }
    } else {
      styleDict.backgroundColor = "Lavender";
    }
    
    return styleDict;
  }

  doDatesOverlap(row, searchedRow) {
    const searchedMin = convertToDate(searchedRow.minConnectedDate);
    const searchedMax = convertToDate(searchedRow.maxConnectedDate);
    const rowMin = convertToDate(row.minConnectedDate);
    const rowMax = convertToDate(row.maxConnectedDate);

    if ((searchedMin <= rowMax)  &&  (searchedMax >= rowMin)) {
      if ((searchedMin === rowMax)  ||  (searchedMax === rowMin)){
        //allow situations where the dates touch to not colour the cell
        return false;
      }
      else {
        return true;
      }
    }
    return false;
  }

  generateTooltipText(row, searchedRow, fieldKey, fieldText) {
    if (row === searchedRow) {
      if (fieldText.source !== undefined) {
        return `${fieldText.name} from ${fieldText.source}`;
      } else {
        return fieldText.name;
      }
    } else {
      if ((searchedRow !== null) &&
          (searchedRow[fieldKey] !== null) &&
          (renderToString(row[fieldKey]) === renderToString(searchedRow[fieldKey]))) {
        return `${fieldText.name} ${fieldText.matchTerm===undefined?"matches":fieldText.matchTerm} ` +
               `the ${fieldText.referenceExtra===undefined?"":fieldText.referenceExtra} searched device ${searchedRow.serialNo}`;
      } else {
        if (row[fieldKey] !== null) {
          if (fieldText.source !== undefined) {
            return `${fieldText.name} from ${fieldText.source}`;
          } else {
            return fieldText.name;
          }
        }
      }
    }
  }

  addTooltips(row, searchedDevice) {
    let changableSource = row.deviceId === null?"installed base":"connected device";
    const fields = {
      serialNo: {name:"Serial No", source: changableSource}, 
      referenceSystemType: {name:"Reference System Type", 
                            source: `${changableSource==="installed base"?"material number":"system type"} mapping`},
      materialName: {name:"Material Name and Number", source: "installed base", referenceExtra: "reference system type of the"},
      macAddress: {name:"Mac Address", source: "connected device"},
      labId: {name:"Lab Id", source: "connected device"},
      remoteServiceDeviceSerialNo: {name:"Remote Service Device Serial No", source: "connected device"},
      dataManagerSerialNo: {name:"Data Manager Serial No", source: "connected device"},
      formattedAccounts: {name:"Account Numbers", matchTerm: "match", source: row.accountsSource === undefined?"installed base":row.accountsSource},
    }

    for (const [fieldName, fieldText] of Object.entries(fields)) {
      if (row[fieldName + "Tooltip"] === undefined) {
        row[fieldName + "Tooltip"] = this.generateTooltipText(row, searchedDevice, fieldName, fieldText);
      }
    };

    //connected dates
    if (row["connectedDatesTooltip"] === undefined) {
      let tooltip = "";
      if (row === searchedDevice) {
        tooltip = "The earliest to latest dates we have received data from this device";
      } else {
        if (this.doDatesOverlap(row, searchedDevice)) {
          tooltip = `Connected at the same time as the searched device  ${searchedDevice.serialNo}`;
        }
      }
      row["connectedDatesTooltip"] = tooltip;
    }
  }

  /**
   * Render the section displaying the device identity
   */
  renderDevicesRow(row, index, searchedDevice) {
    if (row === null) {
      return;
    }
    const keyEnding = index;
    this.addTooltips(row, searchedDevice);
    return (
      <OwcTableRow key={"DevicesAtLocationRow" + keyEnding}>
        <OwcTableCell key={"AccountNoCell" + keyEnding} id={"AccountNoCell" + keyEnding} valign="middle"
            style={this.applyColour(row, searchedDevice, "formattedAccounts", {wordBreak:"normal"})}>
          {row.formattedAccounts}
        </OwcTableCell>
        <DelayedTooltip key={"accountNoCellTooltip" + keyEnding}
            anchor={"AccountNoCell" + keyEnding} 
            disableTriggers={row.formattedAccountsTooltip===undefined || row.formattedAccountsTooltip===""}>
          {row.formattedAccountsTooltip}
        </DelayedTooltip>
        <OwcTableCell key={"MaterialNameCell" + keyEnding} id={"MaterialNameCell" + keyEnding} valign="middle"
            style={this.applyColour(row, searchedDevice, "referenceSystemType", {wordBreak:"normal"})}>
          {row.materialName}<br/>{row.materialNo}
        </OwcTableCell>
        <DelayedTooltip key={"MaterialNameCellTooltip" + keyEnding}
          anchor={"MaterialNameCell" + keyEnding} 
          disableTriggers={row.materialNameTooltip===undefined || row.materialNameTooltip===""}>
            {row.materialNameTooltip}
        </DelayedTooltip>
        <OwcTableCell key={"SerialNoCell" + keyEnding} id={"SerialNoCell" + keyEnding}
            valign="middle"
            style={this.applyColour(row, searchedDevice, "serialNo", {wordBreak:"normal"})}>
          {row.serialNo}
        </OwcTableCell>
        <OwcTableCell key={"SystemTypeCell" + keyEnding} id={"SystemTypeCell" + keyEnding} valign="middle"
            style={this.applyColour(row, searchedDevice, "referenceSystemType", {wordBreak:"normal"})}>
          {row.referenceSystemType}
        </OwcTableCell>
        <DelayedTooltip key={"SystemTypeCellTooltip" + keyEnding}
            anchor={"SystemTypeCell" + keyEnding} 
            disableTriggers={row.referenceSystemTypeTooltip===undefined || row.referenceSystemTypeTooltip===""}>
          {row.referenceSystemTypeTooltip}
        </DelayedTooltip>
        <OwcTableCell key={"ConnectedDatesCell" + keyEnding} id={"ConnectedDatesCell" + keyEnding} valign="middle"
            style={this.applyConnectedDateColour(row, searchedDevice, {wordBreak:"normal"})}>
          {this.formatDateCellText(row)}
        </OwcTableCell>
        <DelayedTooltip key={"ConnectedDatesCellTooltip" + keyEnding}
            anchor={"ConnectedDatesCell" + keyEnding} 
            disableTriggers={row.connectedDatesTooltip===undefined || row.connectedDatesTooltip===""}>
         {row.connectedDatesTooltip}
        </DelayedTooltip>
        <OwcTableCell key={"LabIdCell" + keyEnding} id={"LabIdCell" + keyEnding} valign="middle"
            style={this.applyColour(row, searchedDevice, "labId", {})}>
          {row.labId}
        </OwcTableCell>
        <DelayedTooltip key={"LabIdCellTooltip" + keyEnding}
            anchor={"LabIdCell" + keyEnding} 
            disableTriggers={row.labIdTooltip===undefined || row.labIdTooltip===""}>
          {row.labIdTooltip}
        </DelayedTooltip>
        <OwcTableCell key={"MacAddressCell" + keyEnding} id={"MacAddressCell" + keyEnding} valign="middle"
            style={this.applyColour(row, searchedDevice, "macAddress", {wordBreak:"normal"})}>
          {row.macAddress}
        </OwcTableCell>
        <DelayedTooltip key={"MacAddressCellTooltip" + keyEnding}
          anchor={"MacAddressCell" + keyEnding} 
          disableTriggers={row.macAddressTooltip===undefined || row.macAddressTooltip===""}>
            {row.macAddressTooltip}
        </DelayedTooltip>
        <OwcTableCell key={"RSDserialNoCell" + keyEnding} id={"RSDserialNoCell" + keyEnding} valign="middle"
            style={this.applyColour(row, searchedDevice, "remoteServiceDeviceSerialNo", {wordBreak:"normal"})}>
          {row.remoteServiceDeviceSerialNo}
        </OwcTableCell>
        <DelayedTooltip key={"RSDserialNoCellTooltip" + keyEnding}
            anchor={"RSDserialNoCell" + keyEnding} 
            disableTriggers={row.remoteServiceDeviceSerialNoTooltip===undefined || row.remoteServiceDeviceSerialNoTooltip===""}>
          {row.remoteServiceDeviceSerialNoTooltip}
        </DelayedTooltip>
        <OwcTableCell key={"DMSerialNoCell" + keyEnding} id={"DMSerialNoCell" + keyEnding} valign="middle"
            style={this.applyColour(row, searchedDevice, "dataManagerSerialNo", {wordBreak:"normal"})}>
          {row.dataManagerSerialNo}
        </OwcTableCell>
        <DelayedTooltip key={"DMSerialNoCellTooltip" + keyEnding}
            anchor={"DMSerialNoCell" + keyEnding} 
            disableTriggers={row.dataManagerSerialNoTooltip===undefined || row.dataManagerSerialNoTooltip===""}>
          {row.dataManagerSerialNoTooltip}
        </DelayedTooltip>
      </OwcTableRow>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    const devices = this.state.devices;
    const searchedDevice = this.state.devices.length === 0?  null: this.state.devices[0];
    const style = { maxWidth:"100%", height:"30em" };
    return (
      <OwcTable key={"DevicesTable"} elevated size='dense' loading={!this.state.devicesLoaded} cardProps={{ style: style }}>
        <OwcTableHeader sticky>
          <OwcTableHeaderCell key={"AccountNoHeader"} width="10%" resizable>Account No</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"MaterialNameHeader"} width="15%" resizable>Material Name</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"SerialNoHeader"} width="10%" resizable>Serial No</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"SystemTypeHeader"} width="10%" resizable>System Type</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"ConnectedDatesHeader"} width="10%" resizable>Connected Dates</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"LabIDHeader"} width="15%" resizable>Lab Id</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"MacAddressHeader"} width="10%" resizable>Mac Address</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"RSDSerialNoHeader"} width="10%" resizable>Remote Service Device Serial No</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"DMSerialNoHeader"} resizable>Data Manager Serial No</OwcTableHeaderCell>
        </OwcTableHeader>
        <OwcTableBody>
          <OwcTableGroup sticky>
            <div slot="header" style={{ alignItems: 'center' }}>
              <OwcTypography style={{ flexGrow: 1 }}>Searched device for comparison</OwcTypography>
            </div>
            {this.renderDevicesRow(searchedDevice, -1, searchedDevice)}
          </OwcTableGroup>
          <OwcTableGroup sticky>
            <div slot="header" style={{display: "flex", flexDirection:"row", alignItems: 'center' }} >
              <OwcTypography>Other devices at this location (</OwcTypography>
              <OwcTypography>{this.props.locationNo}</OwcTypography>
              <OwcTypography>)</OwcTypography>
              <OwcIcon name="locator" type="filled" variant="primary"/>
              <OwcTypography style={{ flexGrow: 1 }}>
              {devices.length>=this.DEVICE_LIMIT?
                (
                  <>
                    &nbsp;-&nbsp;More than {this.DEVICE_LIMIT-1} devices found, only showing the first {this.DEVICE_LIMIT-1}
                  </>
                )
                :
                  ""
              }
              </OwcTypography>
            </div>
            {devices.map((row,index) => row === searchedDevice? "":this.renderDevicesRow(row, index, searchedDevice))}
          </OwcTableGroup>
        </OwcTableBody>
      </OwcTable>
    );

  }
}

DevicesAtLocation.defaultProps = {
  deviceId: null,
  locationNo: null,
  systemTypeFilter: null,
}

export default DevicesAtLocation
