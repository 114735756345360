import { OwcTypography } from '@one/react';
import React from 'react';
import {configData} from "../../config.js";
import TextWithLookup from "../general/TextWithLookup.js";
import '../../styles.scss';

/**
 * Renders a read only table of customer agreement details
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class CustomerAgreementDetails extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {
          agreement: props.agreement,
          refListsComplete: props.refLists,
        };
        if ((this.state.refListsComplete !== undefined) 
            && (this.state.refListsComplete !== null) 
            && (this.state.refListsComplete)) {
              this.state.refdataLoaded = true;
            }
    }

    
  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {

    // we might need to update if we have moved to or from MapToAccounts and the filter selected was not All
    if (this.props.refLists !== prevProps.refLists) {
      const isLoaded = ((this.props.refLists !== undefined) 
                      && (this.props.refLists !== null) 
                      && (this.props.refLists));
      this.setState({ refListsComplete: this.props.refLists,
                      refdataLoaded: isLoaded});
    }
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    if (this.state.agreement !== undefined) {
        return (
          <table>
            {this.props.hideTitle?
              <div></div>
            :
              <caption style={{ textAlign: "left", paddingLeft: "0.15em", marginBottom:"0.25em" }}>
                <OwcTypography style={{ fontWeight: "bold" }}>Details from the Customer Agreement</OwcTypography>
              </caption>
            }
            <tbody>
              <tr valign="top">
                <td width="10%" style={{ "fontWeight": "bold" }}>Title</td>
                <td width="40%">{this.state.agreement.title}</td>
                <td width="10%" style={{ "fontWeight": "bold", whiteSpace: "nowrap" }}>Account Number</td>
                <td width="40%" style={{ whiteSpace: "nowrap", position:"relative"}}>
                  <div style={{position:"absolute", top:"-0.3em"}}>
                  <TextWithLookup text={this.state.agreement.customerAccountNo}
                    onClick={() => this.props.onAccountSearchClick(this.state.agreement.customerAccountNo)} 
                    iconTooltip="Search for agreements for this account"
                  />
                  </div>
                </td>
              </tr>
              <tr valign="top">
                <td style={{ "fontWeight": "bold" }}>Customer Name</td>
                <td>{this.state.agreement.customerName}</td>
                <td style={{ "fontWeight": "bold" }}>Customer Address</td>
                <td>{this.state.agreement.customerAddress}</td>
              </tr>
              <tr valign="top">
                <td style={{ "fontWeight": "bold" }}>Roche Affiliate</td>
                <td>
                  {this.lookupRefData(this.state.agreement.rocheAffiliateId,
                    "rocheAffiliateValue" in this.state.agreement ? this.state.agreement.rocheAffiliateValue : null)}
                </td>
                <td style={{ "fontWeight": "bold", whiteSpace: "nowrap" }}>Country</td>
                <td>{this.state.agreement.customerCountry}</td>
              </tr>
            </tbody>
          </table>
        );
    } else {
        return (
            <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px", marginBottom:"1em"}}>
                <OwcTypography style={{fontWeight:"bold", color:"Grey"}}>No Data Clauses</OwcTypography>
            </div>
        );
    }
  }

  /**
   * looks up a ref data item and returns the string with an optional deprecation warning
   * @param {*} refListIdToLookup The id to look up
   * @param {*} alternateValue if not null this will be used for the ref data value
   * @param {*} includeDeprecationWarning if true and the ref list item is not active add a warning
   * @returns the strinbg desctrption of the ref data item, or null if it cannot be found
   */
  lookupRefData(refListIdToLookup, alternateValue = null, includeDeprecationWarning = true) {
    let retVal = alternateValue;
    if (this.state.refdataLoaded) {
      const refDataEntry = this.state.refListsComplete.find(({ refListId }) => refListId === refListIdToLookup);
      if (refDataEntry !== undefined) {
        if (retVal === null) {
          retVal = refDataEntry.description;
        }
        if ((includeDeprecationWarning) && (!refDataEntry.isActive)) {
          retVal += configData.REFDATA_DEPRECATED;
        }
      }
    }
    return (retVal);
  }
  
  voidFn(arg) {
    console.log(arg);
  }

}

CustomerAgreementDetails.defaultProps = {
  agreement: {},
  refLists: {},
  onAccountSearchClick:() => {},
}

export default CustomerAgreementDetails;
