import { OwcTooltip } from '@one/react';
import React from 'react';
import '../../styles.scss';

/**
 * Renders a tooltip with a delay.
 *
 * @copyright Roche 2023
 * @author Geoffroy Maillol
 */
class DelayedTooltip extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      return (
        <OwcTooltip {...this.props}>{this.props.children}</OwcTooltip>
      );
    }
}

DelayedTooltip.defaultProps = {
  enterDelay: '500',
}

export default DelayedTooltip
