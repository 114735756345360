import { OwcIconButton, OwcIcon } from '@one/react';
import DelayedTooltip from "./DelayedTooltip.js";
import React from 'react';
import '../../styles.scss';

/**
 * Renders an icon button that will copy the provided text to the clipboard when clicked on
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class CopyToClipboardButton extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      const keyEnding = this.props.id
      return (
        <>
        <OwcIconButton style={{display: "inline-block", paddingBottom:"4px"}}  key={"copyIconBtn" + keyEnding} id={"copyIconBtn" + keyEnding}
            elevated={!this.props.iconFlat}
            onClick={() => {navigator.clipboard.writeText(this.props.text)}}
            >
            <OwcIcon style={{fontSize:this.props.iconSize}}>
                <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" 
                    width="18" height="18" viewBox="0 0 52 52" enableBackground="new 0 0 52 52" space="preserve">
                    <g>
                        <path d="M17.4,11.6h17.3c0.9,0,1.6-0.7,1.6-1.6V6.8c0-2.6-2.1-4.8-4.7-4.8h-11c-2.6,0-4.7,2.2-4.7,4.8V10
                            C15.8,10.9,16.5,11.6,17.4,11.6z"/>
                        <path d="M43.3,6h-1.6c-0.5,0-0.8,0.3-0.8,0.8V10c0,3.5-2.8,6.4-6.3,6.4H17.4c-3.5,0-6.3-2.9-6.3-6.4V6.8
                            c0-0.5-0.3-0.8-0.8-0.8H8.7C6.1,6,4,8.2,4,10.8v34.4C4,47.8,6.1,50,8.7,50h34.6c2.6,0,4.7-2.2,4.7-4.8V10.8C48,8.2,45.9,6,43.3,6z"
                            fill="transparent" stroke="black"  strokeWidth="2"/>
                    </g>
                </svg>
            </OwcIcon>
            {this.props.iconTooltip?
                <DelayedTooltip key={"copyIconBtnToolTip" + keyEnding} anchor={"copyIconBtn" + keyEnding}>{this.props.iconTooltip}</DelayedTooltip>
                :
                ""
            }
        </OwcIconButton>

        </>
      );
    }
}

CopyToClipboardButton.defaultProps = {
  id: Math.random(),
  text: "",
  iconTooltip: "Copy To clipboard",
  iconFlat: "true",
}

export default CopyToClipboardButton
