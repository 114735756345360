import React from 'react';
import '../../styles.scss';
import {configData} from "../../config.js";

/**
 * Renders a read only matrix of devices against the accounts they relate to
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class DeviceAccountMatrix extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
      super(props);
      this.state = {};
  }


/**
 * Renders the controls of the matrix view
 * @returns The JSX of the controls
 */
  renderMatrix() {
    let includeLocalAgreementColumn = false;
    if ((this.props.localAgreements !== null) 
        && (this.props.localAgreements.length > 0)) {
      includeLocalAgreementColumn = true;
    }
    return(
      <table width="100%" style={{borderStyle:"solid", fontSize: this.props.fontSize}}>
        <tbody>
          <tr valign="top">
            <td></td>
            {includeLocalAgreementColumn?
              (<td style={{borderLeft:"1px dotted grey"}}>Local Agreements</td>)
            :
              ""
            }
            {this.props.accounts.map(account => (
              <td style={{borderLeft:"1px dotted grey"}}>{account.mappedAccountName}</td>
            ))}
          </tr>
          {this.props.devices.map(device => this.renderDeviceRow(device, includeLocalAgreementColumn))}
        </tbody>
      </table>
    );
  }

  /**
   * Renders the controls of the device row of the matrix
   * @returns The JSX of the controls
   */
  renderDeviceRow(device, includeLocalAgreementColumn) {
    let color = configData.COLOUR_WHITE;
    if (device.confirmedStatusCode === 1) {
      color = configData.COLOUR_GREEN;
    }
    if (device.confirmedStatusCode === 2) {
      color = configData.COLOUR_YELLOW;
    }

    return(
      <tr>
          <td style={{whiteSpace:"nowrap", borderTop:"1px dotted grey"}}>{device.referenceSystemType}<br/>{device.serialNo}</td>
          {includeLocalAgreementColumn?
            (
            <td style={{borderTop:"1px dotted grey", borderLeft:"1px dotted grey", flexWrap:"wrap"}} align="center">
              {this.getMatchingLocalAgreements(device).map(label => (
                <owc-chip key={`${device.serialNo}|${device.referenceSystemType}|local|${label}`} 
                  style={{backgroundColor:color}} outlined round>
                    {label}
                </owc-chip>
              ))}
            </td>
            )
          :
            ""
          }
          {this.props.accounts.map(account => {
            const accountRelationList = [];
            if (account.accountNumber === device.sourceShipTo) {
              accountRelationList.push("shipTo");
            }
            if (account.accountNumber === device.sourceSoldTo) {
              accountRelationList.push("soldTo");
            }
            if (account.accountNumber === device.sourceBillTo) {
              accountRelationList.push("billTo");
            }
            if (account.accountNumber === device.sourceLocationAccountNo) {
              accountRelationList.push("location");
            }
            return (
              <td style={{borderTop:"1px dotted grey", borderLeft:"1px dotted grey", flexWrap:"wrap"}} align="center">
                <div style={{display: "flex", alignContent: "center"}}>
                  {accountRelationList.map(label => (
                    <owc-chip key={`${device.serialNo}|${device.referenceSystemType}|${account}|${label}`} 
                      style={{backgroundColor:color}} outlined round>
                        {label}
                    </owc-chip>
                  ))}
                </div>
              </td>
              
            )
          })}
      </tr>
    );
  }

  getMatchingLocalAgreements(device) {
    let matchingLocalAgrements = [];
    if ((this.props.localAgreements !== null) 
        && (this.props.localAgreements.length > 0)) {
      let localAgreementList = this.props.localAgreements.split(",");
      localAgreementList = localAgreementList.map(s => s.trim());

      if (device.localAgreementIds !== null) {
        device.localAgreementIds.split(",").forEach((localAgreement) => {
          const trimmedAgreement = localAgreement.trim();
          if (trimmedAgreement.length > 0) {
            if (localAgreementList.findIndex(localAgreement => localAgreement === trimmedAgreement) !== -1) {
              matchingLocalAgrements.push(trimmedAgreement);
            }
          }
        });
      }
    }
    return matchingLocalAgrements;
  }

  /**
   * Renders the key to the device matrix
   * @returns The JSX of the controls
   */
  renderKey(){
    return (
      <table width="100%" style={{borderStyle:"solid"}}>
        <tbody>
          <tr>
            <td colSpan={2} style={{fontWeight:"bold"}}>Diagram Key</td>
          </tr>
          <tr>
            <td><owc-chip outlined round>shipTo</owc-chip></td>
            <td style={{fontSize:this.props.fontSize}}>matched by shipTo relation</td>
          </tr>
          <tr>
            <td><owc-chip outlined round>soldTo</owc-chip></td>
            <td style={{fontSize:this.props.fontSize}}>matched by soldTo relation</td>
          </tr>
          <tr>
            <td><owc-chip outlined round>billTo</owc-chip></td>
            <td style={{fontSize:this.props.fontSize}}>matched by billTo relation</td>
          </tr>
          <tr>
            <td><owc-chip outlined round>location</owc-chip></td>
            <td style={{fontSize:this.props.fontSize}}>matched by device location</td>
          </tr>
          <tr>
            <td colSpan={2} style={{fontWeight:"bold"}}>Colour Key</td>
          </tr>
          <tr>
            <td><owc-chip style={{backgroundColor:configData.COLOUR_GREEN}} outlined round>&nbsp;&nbsp;</owc-chip></td>
            <td style={{fontSize:this.props.fontSize}}>Confirmed Device</td>
          </tr>
          <tr>
            <td><owc-chip style={{backgroundColor:configData.COLOUR_YELLOW}} outlined round>&nbsp;&nbsp;</owc-chip></td>
            <td style={{fontSize:this.props.fontSize}}>Unconfirmed Device, due to changes</td>
          </tr>
          <tr>
            <td><owc-chip style={{backgroundColor:configData.COLOUR_WHITE}} outlined round>&nbsp;&nbsp;</owc-chip></td>
            <td style={{fontSize:this.props.fontSize}}>Not Confirmed Device, set by data steward</td>
          </tr>
        </tbody>
      </table>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
      if (this.props.includeKey) {
          return (
            <div style={{display:"flex", justifyContent:"space-between", alignContent:"space-between", width:"100%"}}>
                  <div style={{flexGrow:"1", marginRight:"0.5em"}}>{this.renderMatrix()}</div>
                  <div style={{flexGrow:"0", alignSelf:"flex-start"}}>{this.renderKey()}</div>
            </div>
          );
      } else {
        return this.renderMatrix();
      }
  }

}

DeviceAccountMatrix.defaultProps = {
  fontSize: "0.8em",
}

export default DeviceAccountMatrix;
