import React from 'react';
import { configData } from "./config.js";
import './styles.scss';

import {
  formatDate, truncateDate, fetchSigned, joinWithDifferentLastSeperator 
} from "./shared/Utilities.js"

import CustomerAgreementDetails from "./components/agreement/CustomerAgreementDetails.js";

import DeviceAccountMatrix from "./components/device/DeviceAccountMatrix.js";
import TextWithLookup from "./components/general/TextWithLookup.js"

import {
  OwcButton, OwcTypography, OwcSwitch
} from '@one/react';

/**
 * The mainly read only form with a single verify button for showing the account and device mappings for an agreement
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class VerifyDeviceMapping extends React.Component {
  CONFIRMED_STATUS_CONFIRMED = 1;
  CONFIRMED_STATUS_UNCONFIRMED_CHANGE = 2;
  CONFIRMED_STATUS_NOT_CONFIRMED = 3;

  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedAgreementLoading: false,
      selectedAgreementId: this.props.customerAgreementId,
      submissionState: null,
      agreement: {},
      mappedAccounts: [],
      relatedAccounts: [],
      mappedDevices: [],
      refListsComplete: {},
      verificationRecords: [],
      loadRelatedAccountsOngoing: false,
      verificationRecordsLoading: false,
      showMatrixKey: true,
      searchError: "",
      refdataLoaded: false,
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
      this.updateForm(null, this.props.customerAgreementId, true);
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const systemTypesList = result.filter(value =>
            value.type === "Instrument Type" && value.isActive === true
          );
          this.setState({
            refListsComplete: result,
            referenceTypes: systemTypesList,
            refdataLoaded: true
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    this.updateForm(null, this.props.customerAgreementId, true);

  }

  /** Handles the click event on the selection table row
   * @param {*} ev the click event
   * @param {number} agreementId the selected agreement id
   */
  updateForm(ev, agreementId, forceUpdate = false) {
    // if the agreementId is changed then
    if (forceUpdate || (agreementId !== this.state.selectedAgreementId)) {
      // wipe the last agreement
      this.setState({
        selectedAgreementId: agreementId,
        agreement: {},
        submissionState: null,
        mappedAccounts: [],
        mappedDevices: [],
        searchResults: [],
        relatedAccounts: [],
        verificationRecords: [],
        searchError: "",
      });
      this.loadAgreementData(agreementId);
      this.loadMappedAccountsData(agreementId);
      this.loadMappedDevicesData(agreementId);
    }
    // load the new agreement data
    this.loadVerificationRecords(agreementId);
    
  }

  /**
   * Loads the agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
  loadAgreementData(agreementId) {
    this.setState({ selectedAgreementLoading: true });
    //get the agreement details
    let url = configData.CONTRACTS_API_URL + agreementId + "/";
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          const row = result[0];
          this.setState({
            agreement: row,
            performSearch: true,
            selectedAgreementLoading: false
          }, () => this.loadRelatedAccountsData(agreementId));
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Loads mapped accounts for agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
  loadMappedAccountsData(agreementId) {
    console.log("Loading mapped accounts for ", agreementId)
    this.setState({
      mappedAccounts: [],
      selectedAccountMappingsLoading: true
    });
    //get the agreement details
    let url = configData.ACCOUNT_MAPPING_API_URL + "mapped_accounts/" + agreementId + "/";
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            mappedAccounts: result,
            selectedAccountMappingsLoading: false
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Loads mapped devices for agreement and stores the data in the state
   * @param {*} agreementId the selected agreement id
   */
   loadMappedDevicesData(agreementId) {
    console.log("Loading mapped devices for ", agreementId)
    this.setState({
      mappedDevices: [],
      selectedMappingsLoading: true
    });

    // load the related devices list
    fetchSigned(configData.DEVICE_MAPPING_API_URL + "mapped/" + agreementId)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          mappedDevices: result,
          selectedMappingsLoading: false
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error,
          selectedMappingsLoading: false
        });
      }
    )
  }

  /**
   * Loads verification records for agreement and stores the data in the state
   * @param {*} agreementId the selected agreement id
   */
   loadVerificationRecords(agreementId) {
    console.log("Loading verification records for ", agreementId)
    this.setState({
      verificationRecords: [],
      verificationRecordsLoading: true
    });

    // load the related devices list
    fetchSigned(configData.MAPPING_VALIDATION_API_URL + agreementId)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          verificationRecords: result,
          verificationRecordsLoading: false
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error,
          verificationRecordsLoading: false
        });
      }
    )
  }

  /**
   * Loads related accounts for an agreement and stores the data in the state
   * @param {*} agreementId the selected agreement id
   */
   loadRelatedAccountsData(agreementId) {
    console.log("Loading related devices for ", agreementId)
    this.setState({
      relatedAccounts: [],
      loadRelatedAccountsOngoing: true
    });
    if ((this.state.agreement.customerAccountNo !== null) && 
      (this.state.agreement.customerAccountNo !== undefined) && 
      (this.state.agreement.customerAccountNo.length !== 0)) {

      const searchOptions = {
        accountNo: this.state.agreement.customerAccountNo.trim()
      };

      //get the agreement details
      let url = configData.ACCOUNT_MAPPING_API_URL + "search-matching-account/";
      fetchSigned(url, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchOptions)
      })
        .then(res => res.json())
        .then(
          (result) => {
            let sortedResults = result;
            try {
              result.sort((a, b) => a.accountNo.localeCompare(b.accountNo));
            } catch (err) {
              console.log(`Error while sorting RelatedAccountsData results: ${err}`)
            }
            this.setState({
              relatedAccounts: sortedResults,
              loadRelatedAccountsOngoing: false
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        );
    }
  }

  /** Submits a request to store a new verification record to the database, and then refreshes the list of verification records */
  handleConfirmClick() {
    if (this.state.selectedAgreementId !== null) {
      const submissionData = {
        verifiedBy: this.props.userName,
        verifiedDate:  truncateDate(new Date(Date.now()))
      };

      const index = this.state.verificationRecords.findIndex(record => record.verifiedBy === submissionData.verifiedBy &&
        formatDate(record.verifiedDate) === formatDate(submissionData.verifiedDate));
      if (index !== -1) {
        this.setState({ submissionState: "Already confirmed by you today" });
        return;
      }
  
      console.log("Submitting validation record ...", submissionData);
  
      this.setState({ submissionState: "Saving ..." });
  
      fetchSigned(configData.MAPPING_VALIDATION_API_URL + this.state.selectedAgreementId, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        })
          .then((response) => {
            console.log(response);
            if (response.status === 201) // inserted successfully
            {
              response.json().then((json) => {
                this.updateForm(null, this.state.selectedAgreementId);
                this.setState({ submissionState: "Verification record successfully saved" })
                console.log("API Response" + JSON.stringify(json));
              }).catch((error) => {
                this.setState({ submissionState: "Error saving verification record " + error });
                console.error(error);
              });
            } else {
              response.json().then((json) => {
                console.log("API Response" + JSON.stringify(json));
                this.setState({ submissionState: "Error saving verification record " + json.errorText });
              }).catch((error) => {
                this.setState({ submissionState: "Error saving verification record " + error })
                console.error(error);
              });
            }
          });
      };
  }


  /**
   * Gets a formatted string of the mapped and source values
   * @param {*} mappedValue the value when it was mapped
   * @param {*} sourceValue the current value in the source data
   * @returns The formated string
   */
  getMappedInfoMessage(mappedValue, sourceValue) {
    if (mappedValue === sourceValue) {
      return mappedValue;
    }
    else {
      return `Mapped: ${mappedValue}\nNow Changed: ${sourceValue}`
    }
  }

  /**
   * Gets a formatted string of the mapped and source values for use in a tooltip
   * @param {*} fieldName the name of the field for the tooltip
   * @param {*} mappedValue the value when it was mapped
   * @param {*} sourceValue the current value in the source data
   * @returns The formated string
   */
  getMappedInfoTooltip(fieldName, mappedValue, sourceValue) {
    if (mappedValue === sourceValue) {
      return mappedValue;
    }
    else {
      return `${fieldName} has changed at source since it was mapped`
    }
  }

  /**
   * Gets a formatted string of the confirmed staus for use in a tooltip
   * @param {*} confirmedStatusCode the confirmed status code
   * @param {*} confirmedStatusChangeDate the date the status changed
   * @param {*} confirmedStatusChangeBy who made the change
   */
  getConfirmedStatusTooltip(confirmedStatusCode, confirmedStatusChangeDate, confirmedStatusChangeBy) {
    let dateString = formatDate(confirmedStatusChangeDate, false);
    if (confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
      return `Confirmed on ${dateString} by ${confirmedStatusChangeBy}`;
    }
    else {
      if (confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE) {
        return `Unconfirmed change on ${dateString} by ${confirmedStatusChangeBy}, the mapping needs to be re-confirmed`;
      } else {
        return `Not Confirmed set on ${dateString} by ${confirmedStatusChangeBy}`;
      }
    }
  }

  /**
   * returns the text for a tooltip for the search results item
   * @param {*} row the row of the search results
   */
  getAccountSearchTooltip(row) {
    const accountMatch = [];
    if (row.shipTo  === this.state.agreement.customerAccountNo)  {
      accountMatch.push("shipTo")
    }
    if (row.soldTo  === this.state.agreement.customerAccountNo)  {
      accountMatch.push("soldTo")
    }
    if (accountMatch.length > 0) {
      const accountMatchString = `Matched by ${joinWithDifferentLastSeperator(accountMatch)}`;
      return accountMatchString;
    }
  }

    
  /**
   * Renders the Mapped Accounts details
   * @returns The JSX of the controls
   */
  renderMappedAccounts() {
    const mappedAccounts = this.state.mappedAccounts;
    if (mappedAccounts.length === 0) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>No Mapped Accounts</OwcTypography>
      );
    }

    return (
      <>
        <table width="100%">
          <thead>
            <tr style={{ borderBottom: "1px solid #ddd", backgroundColor: "#eee" }}>
              <th width="15%" align="left">Account Number</th>
              <th width="25%" align="left">Name</th>
              <th width="30%" align="left">Address</th>
              <th width="30%" align="left">Confirmation Status</th>
            </tr>
          </thead>
          <tbody>
            {mappedAccounts.map(item => this.renderAccountMappingRow(item))}
          </tbody>
        </table>
      </>
    );
  }
  
  /**
   * Renders the Acccount mapping row
   * @param {*} item the data item for the mapped account
   * @returns the JSX of the controls
   */
  renderAccountMappingRow(item) {
    // determine the status and therefore colour of the row
    let rowColour = configData.COLOUR_WHITE
    if (item.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
      rowColour = configData.COLOUR_GREEN
    } 
    
    if (item.confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE) {
      rowColour = configData.COLOUR_YELLOW
    }
    const rowId = item.accountNumber;
    const addressText = this.getMappedInfoMessage(item.mappedAccountAddress, item.sourceAccountAddress);
    const nameText = this.getMappedInfoMessage(item.mappedAccountName, item.sourceAccountName);

    return (
      <tr key={"MappedAccountRow" + rowId} style={{fontSize:"0.8em",  backgroundColor: rowColour, verticalAlign: "baseline" }}>

        <td key={"MappedAccountNoCell" + rowId}>
          <TextWithLookup text={item.accountNumber} 
              onClick={() => this.props.onAccountSearchClick(item.accountNumber)} 
              iconTooltip="Search for agreements for this account"
            />
        </td>
        <td key={"MappedAccountNameCell" + rowId}>
          {(nameText === null || nameText.split(/\r\n|\r|\n/).length === 1)?
            nameText
          :
          <pre key={"MappedAccountNameCellText" + rowId}
            id={"MappedAccountNameCellText" + rowId}>
            {nameText}
          </pre>
          }
        </td>
        <td key={"MappedAccountAddrCell" + rowId} >
          {(addressText === null || addressText.split(/\r\n|\r|\n/).length === 1)?
            addressText
          :
          <pre key={"MappedAccountAddrCellText" + rowId}
            id={"MappedAccountAddrCellText" + rowId}>
            {addressText}
          </pre>
          }
        </td>
        <td key={"MappedAccountConfirmedStatusCell" + rowId} >
          {this.getConfirmedStatusText(item.confirmedStatusCode, item.confirmedStatusChangeBy, item.confirmedStatusChangeDate)}
        </td>
      </tr>
    );
  }

    
  /**
   * Renders the unmapped Accounts details
   * @returns The JSX of the controls
   */
   renderUnmappedAccounts() {
    if ((this.state.agreement.customerAccountNo === null) || 
      (this.state.agreement.customerAccountNo === undefined) || 
      (this.state.agreement.customerAccountNo.length === 0)) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>No account number defined in the agreement</OwcTypography>
      );
    }
    if (this.state.loadRelatedAccountsOngoing === true) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>Loading ...</OwcTypography>
      );
    }
    let unmappedAccounts = this.state.relatedAccounts.slice();

    if (unmappedAccounts.length > 0)
    {
      for (let i = unmappedAccounts.length - 1; i >= 0; i--) {
        const unmappedAccount = unmappedAccounts[i];
        // check if this account is in the mapped list
        const index = this.state.mappedAccounts.findIndex(({ accountNumber }) => accountNumber === unmappedAccount.accountNo);
        if (index !== -1) {
          //remove it from the list of unmappedAccounts to display as it in mapped
          if (unmappedAccounts.length === 1) {
            unmappedAccounts = [];
          } else {
            unmappedAccounts.splice(i,1);
          }
        }
      }
    }

    if (unmappedAccounts.length === 0) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>No accounts in the agreement that have not been mapped</OwcTypography>
      );
    }

    return (
      <>
        <table width="100%">
          <thead>
            <tr style={{ borderBottom: "1px solid #ddd", backgroundColor: "#eee" }}>
              <th width="15%" align="left">Account Number</th>
              <th width="25%" align="left">Name</th>
              <th width="30%" align="left">Address</th>
            </tr>
          </thead>
          <tbody>
            {unmappedAccounts.map(item => this.renderUnmappedAccountRow(item))}
          </tbody>
        </table>
      </>
    );
  }
  
  /**
   * Renders the details of related accounts that are not mapped
   * @param {*} item The account details for an account that is not mapped
   * @returns The JSX of the controls
   */
  renderUnmappedAccountRow(item) {
    // determine the status and therefore colour of the row
    let rowColour = configData.COLOUR_WHITE
    const rowId = item.accountNo;

    return (
      <tr key={"unmappedAccountRow" + rowId} style={{fontSize:"0.8em",  backgroundColor: rowColour, verticalAlign: "baseline" }}>
        <td key={"unmappedAccountNoCell" + rowId}
         id={"unmappedAccountNoCell" + rowId}>          
          <TextWithLookup text={item.accountNo} 
            onClick={() => this.props.onAccountSearchClick(item.accountNo)} 
            iconTooltip="Search for agreements for this account"
          />
        </td>
        <td key={"unmappedAccountNameCell" + rowId}>{item.accountName}</td>
        <td key={"MappedAccountAddrCell" + rowId} >{item.address}</td>
      </tr>
    );
  }



  /**
   * Renders the Mapped Devices details
   * @returns The JSX of the controls
   */
  renderMappedDevices() {
    const mappedDevices = this.state.mappedDevices;
    if (mappedDevices.length === 0) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>No Mapped Devices</OwcTypography>
      );
    }

    return (
      <>
        <table width="100%">
          <thead>
            <tr style={{ borderBottom: "1px solid #ddd", backgroundColor: "#eee" }}>
              <th width="15%" align="left">Instrument Type</th>
              <th width="15%" align="left">Serial Number</th>
              <th width="20%" align="left">Coverage</th>
              <th width="25%" align="left">Device Location</th>
              <th width="25%" align="left">Confirmation Status</th>
            </tr>
          </thead>
          <tbody>
            {mappedDevices.map(item => this.renderMappingRow(item))}
          </tbody>
        </table>
        <br />
        {this.renderMappingComments(this.state.agreement.deviceMappingComment)}
        {this.state.mappedAccounts.length === 0  &&
        (this.state.agreement.localAgreementIds === null ||
          this.state.agreement.localAgreementIds === undefined ||
          this.state.agreement.localAgreementIds.length === 0)?
          ""
        :
          <>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <div style={{}}>
                <OwcTypography style={{ fontWeight: "bold" }}>Relation between mapped accounts, local agreements and mapped instruments</OwcTypography>
              </div>
              <div style={{}}>
                <OwcSwitch style={{  position: "relative",float: "right", fontWeight: "bold"}}
                    checked={this.state.showMatrixKey} 
                    onValueChange={(ev)=>this.setState({showMatrixKey: ev.detail})}>
                  Show key
                </OwcSwitch>
              </div>
            </div>
            <div style={{height:"50vh", width:"calc(100vw - 18em)", overflowX:"auto", overflowY:"auto"}}>
            <DeviceAccountMatrix 
              localAgreements={this.state.agreement===null?null:this.state.agreement.localAgreementIds} 
              accounts={this.state.mappedAccounts} 
              devices={this.state.mappedDevices} 
              includeKey={this.state.showMatrixKey}
            />
            </div>
          </>
        }
      </>
    );
  }

  /**
   * Renders the row of a Mapped Device
   * @param {*} item The data item for the device mapping
   * @returns The JSX of the controls
   */
  renderMappingRow(item) {
    // determine the status and therefore colour of the row
    let rowColour = configData.COLOUR_WHITE
    if (item.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
      rowColour = configData.COLOUR_GREEN
    } 
    
    if (item.confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE) {
      rowColour = configData.COLOUR_YELLOW
    }
    const rowId = item.serialNo + "|" + item.referenceSystemType;
    const addressText = this.getMappedInfoMessage(item.mappedInstrumentAddress, item.sourceInstrumentAddress);

    return (
      <tr key={"MappedDeviceRow" + rowId} style={{ fontSize:"0.8em", backgroundColor: rowColour, verticalAlign: "baseline" }}>
        <td key={"systemTypeCell" + rowId}>{item.referenceSystemType + (item.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}</td>
        <td key={"SerialNoCell" + rowId} >{item.serialNo}</td>
        <td key={"DatesCell" + rowId} >
          <span style={{whiteSpace:"nowrap"}}>{item.validityStartDate===null?"start":formatDate(item.validityStartDate)}</span>
          <span> - </span>
           <span style={{whiteSpace:"nowrap"}}>{item.validityEndDate===null?"end":formatDate(item.validityEndDate)}</span>
        </td>
        <td key={"InstAddrCell" + rowId} >
          {(addressText === null || addressText.split(/\r\n|\r|\n/).length === 1)?
            addressText
          :
          <pre key={"InstAddrCellText" + rowId}
            id={"InstAddrCellText" + rowId}>
            {addressText}
          </pre>
          }
        </td>
        <td key={"ConfirmedStatusCell" + rowId} >{this.getConfirmedStatusText(item.confirmedStatusCode, item.confirmedStatusChangedBy, item.confirmedStatusChangeDate)}</td>
      </tr>
    );

  }

  /**
   * creates the text to display for the cuonfirmed status
   * @param {*} confirmedStatusCode the status code
   * @param {*} confirmedStatusChangedBy the user that made the last change
   * @param {*} confirmedStatusChangeDate the data time of the last change
   */
  getConfirmedStatusText(confirmedStatusCode, confirmedStatusChangedBy, confirmedStatusChangeDate){
    const confirmedStatusText = {}
    confirmedStatusText[this.CONFIRMED_STATUS_CONFIRMED] = "Confirmed";
    confirmedStatusText[this.CONFIRMED_STATUS_NOT_CONFIRMED] = "Not Confirmed";
    confirmedStatusText[this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE] = "Unconfirmed";

    if (this.props.dataSteward === true) {
      return `${confirmedStatusText[confirmedStatusCode]} on ${formatDate(confirmedStatusChangeDate)} by ${confirmedStatusChangedBy}`;
    } else {
      return `${confirmedStatusText[confirmedStatusCode]} on ${formatDate(confirmedStatusChangeDate)}`;
    }
  }

  /**
   * Renders the current mapped Devices table
   * @returns The JSX of the controls
   */
  renderMappingComments(textToDisplay) {
    if (textToDisplay) {
      return (
        <table>
          <tbody>        
          <tr>
            <td style={{paddingRight:"1em", verticalAlign:"top", fontWeight:"bold"}}>Comments</td>
            <td style={{verticalAlign:"top", whiteSpace:"pre-wrap"}}>{textToDisplay}</td>
          </tr>
          </tbody>
        </table>
      );
    }
  }

  
 /**
   * Renders the verification records table
   * @returns The JSX of the controls
   */
  renderVerificationRecords() {
    const verificationRecords = this.state.verificationRecords;

    if (this.state.verificationRecordsLoading){
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>Loading ...</OwcTypography>
      );
    }

    if (verificationRecords.length === 0) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>Not verified yet</OwcTypography>
      );
    }

    return (
      <>
        <table>
          <tbody>
            {verificationRecords.map(verification => this.renderVerificationRow(verification))}
          </tbody>
        </table>
      </>
    );
  }

  /**
   * Renders a row of the verification records
   * @param {*} verification The verification data record
   * @returns The JSX of the controls
   */
  renderVerificationRow(verification) {
    const rowId = verification.verifiedDate;

    return (
      <tr key={"VerificationRow" + rowId} style={{verticalAlign: "top" }}>
        <td key={"VerificationTextCell" + rowId}>
         {this.props.dataSteward===true?
            (
            <>
              I, {verification.verifiedBy} hereby confirm on {formatDate(verification.verifiedDate)}
              &nbsp;that the mapped accounts and mapped devices are correct.
            </>
            )
           :
            (
            <>
              Mapped accounts and mapped devices confirmed on {formatDate(verification.verifiedDate)}.
            </>
            )
           }
        </td>
      </tr>
    );

  }

  /**
   * Renders the form
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    if (this.state.selectedAgreementId === null) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an agreement in "Capture Agreement" first</OwcTypography>);
    }

    if (this.state.selectedAgreementLoading === true || this.state.refdataLoaded === false) {
      return (<OwcTypography variant="title6">Loading ...</OwcTypography>);
    }

    return (
      <div style={{marginLeft:"0.5em"}}>
        <OwcTypography variant="title5" style={{ fontWeight: "bold" }}>Agreement</OwcTypography>
        <hr />
        <CustomerAgreementDetails agreement={this.state.agreement} 
          refLists={this.state.refListsComplete}
          hideTitle="true"
          onAccountSearchClick={(arg) => this.props.onAccountSearchClick(arg)}
        />
        <br />
        <OwcTypography variant="title5" style={{ fontWeight: "bold" }}>Account(s) mapped to the Customer Agreement</OwcTypography>
        <hr />
        {this.renderMappedAccounts()}
        <br/>
        {this.renderMappingComments(this.state.agreement.accountMappingComment)}
        <br/>
        <OwcTypography variant="title5" style={{ fontWeight: "bold" }}>Account(s) with the same Account Numbers(s) but not mapped to the Customer Agreement</OwcTypography>
        <hr />
        {this.renderUnmappedAccounts()}
        <br/><br/>
        <OwcTypography variant="title5" style={{ fontWeight: "bold" }}>Device(s) mapped as covered by the Customer Agreement</OwcTypography>
        <hr />
        {this.renderMappedDevices()}
        <br/><br/>
        <OwcTypography variant="title5" style={{ fontWeight: "bold" }}>Verification Record(s) for Mapped Accounts and Mapped Devices</OwcTypography>
        <hr />
        {this.renderVerificationRecords()}
        <br/><br/>
        {this.props.dataSteward?
        (
          <div>
            <table width="100%">
              <tbody>
                <tr>
                  <td align="right">
                    <OwcButton style={{ width: "fit-content" }}
                        onclick={() => this.handleConfirmClick()}
                        disabled={(this.state.submissionState === "Saving ...")? true : false}>
                      {this.state.submissionState === "Saving ..." ? this.state.submissionState : "I confirm the mappings"}
                    </OwcButton>
                  </td>
                </tr>
                <tr><td align="right">
                  <OwcTypography variant="title6" style={{ marginBottom: 8, color: messageColour }}>
                    {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
                  </OwcTypography>
                </td></tr>
              </tbody>
            </table>
          </div>
        )
        :
          <table width="100%">
            <tbody><tr><td align="right">
              <OwcTypography variant="title6">
                Only Data Stewards can verify mappings
              </OwcTypography>
            </td></tr></tbody>
          </table>
        }
      </div>
    );

  }
}


export default VerifyDeviceMapping;
