import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Entry point from index.html
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */

const AppEntry = () => {
  return <StrictMode><App /></StrictMode>
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppEntry />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
