import React from 'react';
import {configData} from "./config.js";
import ModalDialogConfirmation from "./components/general/ModalDialogConfirmation.js"
import './styles.scss';

import {
  formatDate, fetchSigned, joinWithDifferentLastSeperator, 
  joinWithNoEmptyValues, getDescriptionForRefDataId
} from './shared/Utilities.js'
import DelayedTooltip from "./components/general/DelayedTooltip.js";
import {
  OwcTable, OwcTableHeader, OwcTableHeaderCell,
  OwcTableBody, OwcTableRow, OwcTableCell,
  OwcTypography, OwcPagination,
  OwcInput, OwcIconButton, OwcIcon, OwcButton
} from '@one/react';

/**
 * The a navigation control for selecting agreements
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class AgreementNavigation extends React.Component {

  ACCOUNT_MAPPINGS_NEED_ATTENTION = "Account Mappings";
  DEVICE_MAPPINGS_NEED_ATTENTION = "Device Mappings";
  NEEDS_ATTENTION_FLAG = "Needs Attention";
  ANY_REASON_FLAG = "Any Reason";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      affiliateList: [],
      searchBy: "Title",
      searchTerm: "",
      sortBy: "Most Recent",
      affiliateFilter: "All",
      accountMappingFilter: "All",
      deviceMappingFilter: "All",
      agreementPublishedFilter: "All",
      deviceVerifiedFilter: "All",
      attentionFilter: "All",
      sortList: ["Most Recent",
        "Title",
        "Customer name",
        "Customer account number",
        "Customer country",
        "Customer address",
        "Roche name",
        "Roche country",
        "Roche address",],
      searchList: ["Title",
        "Customer name",
        "Customer account number",
        "Customer country",
        "Customer address",
        "Roche name",
        "Roche country",
        "Roche address",],
      page: 1,
      rowsPerPage: 10,
      totalPages: 1,
      copyAgreementConfirmationVisible: false,
      copyAgreementId: null,
    };

    if (props.accountFilterSettings.startsWith(this.NEEDS_ATTENTION_FLAG)) {
      this.state = this.clearFilters(this.state);
      this.state.attentionFilter = this.ACCOUNT_MAPPINGS_NEED_ATTENTION;
    }
    if (props.deviceFilterSettings.startsWith(this.NEEDS_ATTENTION_FLAG)) {
      this.state = this.clearFilters(this.state);
      this.state.attentionFilter = this.DEVICE_MAPPINGS_NEED_ATTENTION;
    }      
    if (props.searchAccountNo && Object.keys(props.searchAccountNo).length > 0) {
      this.state = this.clearFilters(this.state);
      const accountNo = Object.keys(props.searchAccountNo)[0]
      this.state.searchBy = "Customer account number";
      this.state.searchTerm = accountNo;
    }
  }

  /*** helper method to clear all of the filter options and return the dictionary to be applied to the state
   * @param inputDict optional a dictionary to make the changes to
   */
  clearFilters(inputDict={}){
    inputDict.searchBy = "Title";
    inputDict.searchTerm = "";
    inputDict.sortBy = "Most Recent";
    inputDict.affiliateFilter = "All";
    inputDict.attentionFilter = "All";
    inputDict.agreementPublishedFilter = "All";
    inputDict.deviceVerifiedFilter = "All";
    if (this.props.userPreferences !== undefined && 
      this.props.userPreferences.affiliateRefListId !== undefined && 
      this.props.userPreferences.affiliateRefListId !== null && 
      this.state.affiliateList.length > 0) {
      const affiliateDefault = getDescriptionForRefDataId(this.props.userPreferences.affiliateRefListId,this.state.affiliateList);
      if (affiliateDefault !== null) {
        inputDict.affiliateFilter = affiliateDefault;
      }
    }
    return inputDict;
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    let mustRefreshResults = false;

    // we might need to update if we have moved to or from MapToAccounts and the filter selected was not All
    if (this.props.accountFilterSettings.length > 0) {
      if (this.props.accountFilterSettings !== prevProps.accountFilterSettings) {
        if (this.props.accountFilterSettings.startsWith(this.NEEDS_ATTENTION_FLAG)) {
            const newState = this.clearFilters();
            newState.attentionFilter = this.ACCOUNT_MAPPINGS_NEED_ATTENTION;
            this.setState(newState, () => this.loadSearchResults());
        } else {
          this.setState({accountMappingFilter: "All"}, () => this.loadSearchResults());
        }
      }
    }
    if (this.props.deviceFilterSettings.length > 0) {
      if (this.props.deviceFilterSettings !== prevProps.deviceFilterSettings) {
        if (this.props.deviceFilterSettings.startsWith(this.NEEDS_ATTENTION_FLAG)) {
            const newState = this.clearFilters();
            newState.attentionFilter = this.DEVICE_MAPPINGS_NEED_ATTENTION;
            this.setState(newState, () => this.loadSearchResults());
        } else {
          this.setState({deviceMappingFilter: "All"}, () => this.loadSearchResults());
        }
      }
    }

    if (this.props.searchAccountNo !== prevProps.searchAccountNo) {
      if (this.props.searchAccountNo && Object.keys(this.props.searchAccountNo).length > 0) {
        const accountNo = Object.keys(this.props.searchAccountNo)[0]
        const newState = this.clearFilters();
        newState.searchBy = "Customer account number";
        newState.searchTerm = accountNo;
        this.setState(newState, ()  => this.loadSearchResults());
      }
    }

    if (this.props.userPreferences !== prevProps.userPreferences && 
      this.props.userPreferences !== undefined && 
      this.props.userPreferences.affiliateRefListId !== undefined && 
      this.state.affiliateList.length > 0) {
        let affiliateDefault = getDescriptionForRefDataId(this.props.userPreferences.affiliateRefListId,this.state.affiliateList);
        if (affiliateDefault !== null || this.props.userPreferences.affiliateRefListId === null) {
          if (affiliateDefault === null) {
            affiliateDefault = "All";
          }
          
          if (prevProps.userPreferences === undefined ||
            this.props.userPreferences.affiliateRefListId !== prevProps.userPreferences.affiliateRefListId) {
            // if something has changed
            if (this.state.affiliateFilter !== affiliateDefault) {
              // if this would change the value
              let affiliateDefaultPrev = getDescriptionForRefDataId(prevProps.userPreferences.affiliateRefListId,this.state.affiliateList);
              if (affiliateDefaultPrev === null) {
                affiliateDefaultPrev = "All";
              }
              if (this.state.affiliateFilter === affiliateDefaultPrev) {
                // if we are currently set to the default
                this.setState({affiliateFilter: affiliateDefault}, () => this.loadSearchResults());
              }
            }
          }
        }
    }
     
    // the role toggle has been flipped, remove any filter options
    // this makes sure that no filters remain that the role might not have access to.
    if (this.props.dataSteward !== prevProps.dataSteward) {
      const newState = this.clearFilters();
      this.setState(newState, ()  => this.loadSearchResults());
    }
    
    // we have been signalled to update
    if ((this.props.updateFlag !== prevProps.updateFlag) || mustRefreshResults) {
      this.loadSearchResults();
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredAffiliateList = result.filter(value =>
            value.type === "Affiliate"
          );
          const newStateChanges = { affiliateList: filteredAffiliateList };
          if (this.props.userPreferences !== undefined && 
              this.props.userPreferences.affiliateRefListId !== undefined && 
              this.props.userPreferences.affiliateRefListId !== null) {           
            const affiliateDefault = getDescriptionForRefDataId(this.props.userPreferences.affiliateRefListId,filteredAffiliateList);
            if (affiliateDefault !== null) {
              newStateChanges.affiliateFilter = affiliateDefault;
            }
          }
          this.setState(newStateChanges, () => this.loadSearchResults());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error: "Error Loading Reference Data " + error
          });
        }
      )
  }

  /**
   * Loads the results of the search from the DB into the state
   * @param resetPage True if the pagination of the results should be reset to 1 (default true)
   */
  loadSearchResults(resetPage=true) {
    const pageNumber = resetPage? 1: this.state.page
    this.setState({
      isLoaded: false,
      page: pageNumber,
      error: null
    });
    //gather searchData
    const searchData = {
      searchBy: this.state.searchBy,
      searchTerm: this.state.searchTerm,
      sortBy: this.state.sortBy,
      affiliateFilter: this.getIdForRefData(this.state.affiliateFilter, this.state.affiliateList),
      agreementPublishedFilter: this.state.agreementPublishedFilter,
      deviceVerifiedFilter: this.state.deviceVerifiedFilter,
      limit: this.state.rowsPerPage,
      offset: (pageNumber - 1) * this.state.rowsPerPage,
    };

    // add tab specific filters
    if (this.props.dataSteward)
    {
      if ([this.ANY_REASON_FLAG, this.ACCOUNT_MAPPINGS_NEED_ATTENTION].includes(this.state.attentionFilter)) {
        searchData.accountMappingFilter = this.NEEDS_ATTENTION_FLAG;
      }
      if ([this.ANY_REASON_FLAG, this.DEVICE_MAPPINGS_NEED_ATTENTION].includes(this.state.attentionFilter)) {
        searchData.deviceMappingFilter = this.NEEDS_ATTENTION_FLAG;
      }
    }

    console.log(JSON.stringify(searchData));

    fetchSigned(configData.CONTRACTS_API_URL + "search/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          const maxPage = Math.ceil(result.totalResultCount / this.state.rowsPerPage);
          this.setState({
            isLoaded: true,            
            totalPages: maxPage,
            items: result.searchResults,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (errorMsg) => {
          this.setState({
            isLoaded: true,
            error: "Error Loading Agreements" + errorMsg
          });
        }
      )
  }

  /**
   * Gets the refListId for a reference list text value
   * @param value The text value of the reference list entry
   * @param refdataList The in memory reference list to search for the corresponding refListId
   * @returns The matching refListId or null if a match cannot be found
   */
  getIdForRefData(value, refdataList) {
    let retVal = null;
    const searchText = value.endsWith(configData.REFDATA_DEPRECATED)? 
                          value.slice(0, -configData.REFDATA_DEPRECATED.length)
                          : value;

    const refDataItem = refdataList.find(({ description }) => description === searchText);
    //get the record
    if (refDataItem !== undefined) {
      retVal = refDataItem.refListId;
    }
    return retVal;
  }

  /**
   * Handles the click of the reset button
   */
  handleResetClick() {
    const newState = this.clearFilters();
    this.setState(newState, ()  => this.loadSearchResults());
  }

  /**
   * Handles changes to the pagination of the results
   * @param {*} ev The event object that describes the change
   */
  handlePageChange(ev) {
    const stateChanges = {};
    if (ev.pageSize !== this.state.rowsPerPage) {
      stateChanges.rowsPerPage = ev.pageSize;
    }
    if (ev.page !== this.state.page) {
      stateChanges.page = ev.page;
    }
    //if there are any state changes
    if (stateChanges)
    {
      this.setState(stateChanges, () => this.loadSearchResults(false));
    }
  }

  /**
   * Handles changes to the affiliates filter
   * @param {*} ev The event object that describes the change
   */
  handleAffiliateFilterChange(ev) {
    if (ev.target.value !== this.state.affiliateFilter) {
      this.setState({ affiliateFilter: ev.target.value }, () => this.loadSearchResults());
    }
  }

  /**
   * Handles changes to the sort listbox
   * @param {*} ev The event object that describes the change
   */
  handleSortChange(ev) {
    if (ev.target.value !== this.state.sortBy) {
      this.setState(
        {
          sortBy: ev.target.value,
          page: 1,
        },
        () => this.loadSearchResults());
    }
  }

  /**
   * Handles clicks on the search button
   * @param {*} ev The event object that describes the change
   */
  handleSearchClick(ev) {
    this.loadSearchResults();
  }

  /**
   * Handles Pressing return in the search textbox to trigger a search
   * @param {*} ev The event object that describes the change
   */
  handleSearchKeyUp(ev) {
    // Number 13 is the "Enter" key on the keyboard
    this.setState({ searchTerm: ev.target.value });
    if (ev.keyCode === 13) {
      // Cancel the default action, if needed
      ev.preventDefault();
      // Start the search
      this.loadSearchResults();
    }
  }

  /**
   * Handles clicks on the edit button for an agreement
   * Passthrough method to this.props.onEditClick(ev, customerAgreementId)
   * @param {*} ev The event object that describes the change
   * @param customerAgreementId The customerAgreementId of the agreement to be edited
   */
  handleEditClick(ev, customerAgreementId){
      this.props.onEditClick(ev, customerAgreementId);
  }

  /**
   * Handles clicks on the Copy from Template button for an agreement
   * Shows a confirmation dialog
   * @param {*} ev The event object that describes the change
   * @param customerAgreementId The customerAgreementId of the agreement to be copied
   */
  handleCopyTemplateClick(ev, customerAgreementId){
    //show the confirmation dialog
    this.setState({copyAgreementConfirmationVisible: true,
                    copyAgreementId: customerAgreementId});
  }

  /** Called after the confirmation to create a new agreement using a template has been approved
   * 
   * @param {*} customerAgreementId The Agreement id to use as a template
   */
  createAgreementUsingTemplate(customerAgreementId){
    console.log(`copy agreement using ${customerAgreementId} as a template`);

    //first call the API to make the copy
    const bodyData = {
      templateCustomerAgreementId: customerAgreementId,
      createdBy: this.props.userName,
    };

    console.log(JSON.stringify(bodyData));

    fetchSigned(configData.CONTRACTS_API_URL + "copy/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          if ("errorText" in result) {
            let errorString = result.errorText;
            if (result.errorText.includes("unique_customer_agreements"))
            {
              const re = /=\((.+?),\s+(.+?),\s+(.+?),\s+(.+?)\)/;
              let found = result.errorText.match(re);
              errorString = `Cannot copy the agreement as a duplicate agreement already exists`
              if (found) {
                errorString = ( <>
                                  <OwcTypography style={{color: "red"}}>{errorString} </OwcTypography>
                                  <ul>
                                    <li>Title: {found[1]}</li>
                                    <li>Roche Name: {found[3]}</li>
                                    <li>Customer Name: {found[2]}</li>
                                    <li>Valid From: {found[4]==="1900-01-01"?"No Value": found[4]}</li>
                                  </ul>
                                </>);
              }
            }
            console.log(errorString);
            this.setState({error: errorString});
          }
          else {
            console.log(`new agreement created ${result.newCustomerAgreementId} ` +
                        `using ${result.templateCustomerAgreementId} as a template`);
            this.props.onEditClick(null, result.newCustomerAgreementId);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.error(`ERROR:  ${error} `);
          this.setState({
            isLoaded: true,
            error: error
          });
        }
      )
  }

  /**
   * Renders the agreements table control
   * @returns The JSX of the controls
   */
  renderTable() {
    const error = this.state.error;
    const isLoaded = this.state.isLoaded;
    const items = this.state.items;

    if (error) {
      return <div style={{ paddingTop: "1em", paddingBottom: "23em" , color: "red"}}>Error: {error}</div>;
    } else {
      return (
        <OwcTable key={"AgreementNavigationTable"} style={{ display: "block"}} size='small' elevated showRowExpanderColumn >
          <OwcTableHeader sticky>
              <OwcTableHeaderCell key="titleHeader" resizable width="30%">Agreement Title</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleCustName" resizable width="30%">Customer name</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleRocheName" resizable width="20%">Roche name</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleStatus" resizable width="10%">Status</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleActions" resizable >Actions</OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody loading={!isLoaded}>
            {items.map(item => this.renderTableRow(item))}
          </OwcTableBody>
        </OwcTable>
      );
    }
  }

  /**
   * Determines the status for an agreement
   * @param {*} item The agreemment row
   * @returns A dictionary of status text, row colour and tabs that need attention
   */
  getAgreementStatus(item){
    let status = "";
    let rowColour = configData.COLOUR_WHITE;
    let tabList = []    
    
    // check if anything needs attention - accounts
    if ((item.accountUnconfirmedChange !== null && item.accountUnconfirmedChange > 0) ||
        (item.accountNameChanged !== null && item.accountNameChanged > 0) ||
        (item.accountAddressChanged !== null && item.accountAddressChanged > 0)) {
      rowColour = configData.COLOUR_YELLOW;
      tabList.push(configData.TAB_MAP_ACCOUNTS);
      status = this.NEEDS_ATTENTION_FLAG;
    }    

    // check if anything needs attention - devices
    if ((item.deviceUnconfirmedChange !== null && item.deviceUnconfirmedChange > 0)) {
      rowColour = configData.COLOUR_YELLOW;
      tabList.push(configData.TAB_MAP_DEVICES);
      status = this.NEEDS_ATTENTION_FLAG;
    }

    return({status:status,
            colour: rowColour,
            tabList:tabList});
  }

  /**
   * Renders a row in the agreement serach results table
   * @param {*} item The data item for the agreement
   * @returns The JSX of rendered components
   */
  renderTableRow(item){
    const rowStatus = this.getAgreementStatus(item);
    let rowColour = rowStatus.colour;
    let tabList = rowStatus.tabList;

    let tooltip = "";
    
    if (tabList.length > 0) {
      tooltip = "Needs attention on " + joinWithDifferentLastSeperator(tabList);
    }

    return (
      <OwcTableRow
          key={"row" + item.customerAgreementId}
          id={"row" + item.customerAgreementId} 
          expandable 
          style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour}: {}}
          ondblclick={(ev) => this.handleEditClick(ev, item.customerAgreementId)}>
        <OwcTableCell 
            style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"titleCell" + item.customerAgreementId}>
          {item.title}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"customerNameCell" + item.customerAgreementId}>
          {item.customerName}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"rocheNameCell" + item.customerAgreementId}>
          {item.rocheName}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"rocheStatusCell" + item.customerAgreementId}>
          <div style={{ display: "flex"}}>
            {item.publishedVersionNo !== null
            ?<>
                <OwcIcon key={"iconPublishedStatus" + item.customerAgreementId} 
                  id={"iconPublishedStatus" + item.customerAgreementId} 
                  name="certificate" variant="success"
                  style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
                <DelayedTooltip key={"iconPublishedStatusTooltip" + item.customerAgreementId} anchor={"iconPublishedStatus" + item.customerAgreementId}>
                  Agreement entilements version {item.publishedVersionNo} have been published</DelayedTooltip>          
              </>
            :<div key={"iconPublishedStatusSpacer" + item.customerAgreementId} style={{height:"0.95em"}} />
            }
            {item.deviceVerifiedDate !== null
            ?<>
                <OwcIcon key={"iconVerifiedStatus" + item.customerAgreementId} 
                  id={"iconVerifiedStatus" + item.customerAgreementId} 
                  name="cobas_liat" variant="success"
                  style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
                <DelayedTooltip key={"iconVerifiedStatusTooltip" + item.customerAgreementId} anchor={"iconVerifiedStatus" + item.customerAgreementId}>
                  Device mappings have been verified on {formatDate(item.deviceVerifiedDate)}</DelayedTooltip>
              </>
            :<div key={"iconVerifiedStatusSpacer" + item.customerAgreementId} style={{height:"0.95em"}} />
            }
          </div>
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"rocheActionsCell" + item.customerAgreementId}>
          <div style={{ display: 'flex', flexDirection:"row" }}>
            <OwcIconButton style={{display: "inline-block"}}  
                key={"iconBtnEdit" + item.customerAgreementId} 
                id={"iconBtnEdit" + item.customerAgreementId}
                elevated={!this.props.iconFlat}
                variant="secondary"
                size="xSmall"
                onClick={(ev) => this.handleEditClick(ev, item.customerAgreementId)}>
              {
                this.props.isReadOnly 
                  ? <OwcIcon name="element_show" />
                  : <OwcIcon name="edit" />
              }
            </OwcIconButton>
            <DelayedTooltip key={"iconBtnEditTooltip" + item.customerAgreementId} anchor={"iconBtnEdit" + item.customerAgreementId}>
              {
                this.props.isReadOnly
                  ? "View this agreement"
                  : "Edit this agreement"
              }
            </DelayedTooltip>    
            {
              this.props.isReadOnly
              ? ""
              : (
                <div style={{ display: 'flex'}} >
                  <OwcIconButton style={{display: "inline-block", marginLeft:"0.5em"}}
                      key={"iconBtnCopyTemplate" + item.customerAgreementId} 
                      id={"iconBtnCopyTemplate" + item.customerAgreementId}
                      variant="secondary"
                      size="xSmall"
                      elevated={!this.props.iconFlat}
                      onClick={(ev) => this.handleCopyTemplateClick(ev, item.customerAgreementId)}>
                    <OwcIcon name="file_create" />
                  </OwcIconButton>
                  <DelayedTooltip key={"iconBtnCopyTemplateTooltip" + item.customerAgreementId} anchor={"iconBtnCopyTemplate" + item.customerAgreementId}>
                    Use as a template for a new agreement
                  </DelayedTooltip>
                </div>
              )
            }
          </div>
        </OwcTableCell>
        <div key={"expandedRow" + item.customerAgreementId} slot="expanded" style={{ display: 'flex', flexDirection:"column" }}>
          <div><b>Customer:</b> {joinWithNoEmptyValues([item.customerName, item.customerAddress, item.customerCountryName])}</div>
          <div>{item.customerAccountNo===null?"":<><b>Customer account number</b>: {item.customerAccountNo}<br /></>}</div>
          <div><b>Roche:</b> {joinWithNoEmptyValues([item.rocheName, item.rocheAddress, item.rocheCountryName])}<br/></div>
          <div>
            <b>Valid from:</b> {item.validFrom === null ? "undefined date" : formatDate(item.validFrom)}, 
            <b>To:</b> {item.terminationDate === null ? "undefined date" : formatDate(item.terminationDate)}
          </div>
        </div>
        <DelayedTooltip key={"rowAgreementToolTip" + item.customerAgreementId}
            disableTriggers = {(tooltip === "")}
            anchor={"row" + item.customerAgreementId}>
         {tooltip}
        </DelayedTooltip>
      </OwcTableRow>
    );
  }

  /**
   * Renders the tab specific filter controls for the Map to Accounts Tab
   * @returns The JSX of the controls
   */
  renderSelectFilter(title, stateKey, optionList, widthString="100%") {
    return(
      <tr>
        <td> <OwcTypography>{title}</OwcTypography> </td>
        <td>
          <select style={{ width: widthString, position: "relative", zIndex:"2"}}
            value={this.state[stateKey]}
            onChange={(ev) => {
                if (ev.target.value !== this.state[stateKey]) {
                const newState = {};
                newState[stateKey] = ev.target.value;
                this.setState(newState, () => this.loadSearchResults());
              }
            }}
          >
            {optionList.map((item,index) => (
              <option key={"filter" + stateKey + index}>{item}</option>
            )
            )}
          </select>
        </td>
      </tr>
    );
  }
  
  /**
 * Renders the affiliate filter control
 * @returns The JSX of the controls
 */
  renderSearchAndSort() {
    return (
      <div style={{display:"flex", justifyContent:"space-between", 
                   marginTop:"0.8em", alignItems: "flex-start"}}>
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
                position: "absolute",
                top: "-1em",
                fontSize: "0.75em",
                backgroundColor: "white",
                border: "1px solid white", zIndex:"1"
              }}>
            Search
          </div>
          <table>
            <tbody>
              <tr>
                <td> <OwcTypography style={{position: "relative", zIndex:"2"}}>Search by:</OwcTypography> </td>
                <td>
                  <select style={{ width: "100%", position: "relative", zIndex:"2" }}
                      value={this.state.searchBy}
                      onChange={(ev) => { this.setState({ searchBy: ev.target.value }) }} >
                    {this.state.searchList.map(item => (<option key={"NavSearch" + item}>{item}</option>))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <div style={{display:"flex", alignItems:"center"}}>
                    <OwcInput
                        style={{ width: "100%" }}
                        placeholder="Press Enter to Search..."
                        round={false}
                        variant="filled"
                        no-clear-icon
                        value={this.state.searchTerm}
                        onKeyUp={(ev) => this.handleSearchKeyUp(ev)}>
                      <OwcIconButton slot="prefix" icon="search" onClick={(ev) => this.handleSearchClick(ev)} />
                    </OwcInput>
                    <OwcButton style={{marginLeft:"0.5em"}} elevated
                        onClick={(ev) => this.handleSearchClick(ev)} >
                      Search
                    </OwcButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
              position: "absolute",
              top: "-1em",
              fontSize: "0.75em",
              backgroundColor: "white",
              border: "1px solid white", zIndex:"1"}}>
            Filter by agreement status
          </div>
            <table><tbody>
            {this.renderSelectFilter("Published Agreements:","agreementPublishedFilter", ["All", "Only Published", "Not Published"])}
            {this.renderSelectFilter("Verified Device Mappings:","deviceVerifiedFilter", ["All", "Only Verified", "Awaiting verification"])}
            {
              this.props.dataSteward?
                this.renderSelectFilter("Needing Attention:","attentionFilter", ["All",
                                                                                 this.ACCOUNT_MAPPINGS_NEED_ATTENTION,
                                                                                 this.DEVICE_MAPPINGS_NEED_ATTENTION,
                                                                                 this.ANY_REASON_FLAG])
                : "" 
            }
  
          </tbody></table>
        </div>


        <div>
          <table>
            <tbody> 
              <tr>
                <td><OwcTypography>Filter by Affiliate:</OwcTypography></td>
                <td>
                  <select style={{ width: "100%", maxWidth:"25em"}}
                      value={this.state.affiliateFilter}
                      onChange={(ev) => this.handleAffiliateFilterChange(ev)}>
                    <option>All</option>
                    {this.state.affiliateList.map(item => (
                      <option key={"NavAffiliate" + item.refListId}>
                        {item.isActive?item.description:item.description + configData.REFDATA_DEPRECATED}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>          
              <tr>
                <td> <OwcTypography>Sort by:</OwcTypography> </td>
                <td>
                  <select style={{ width: "100%" }}
                      value={this.state.sortBy}
                      onChange={(ev) => this.handleSortChange(ev)}>
                    {this.state.sortList.map(item => (
                      <option key={"NavSort" + item}>{item}</option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td align="right"> 
                  <OwcButton elevated onClick={() => this.handleResetClick()}>Reset</OwcButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  
  /**
 * Renders the copy template confirmation dialog
 * @returns The JSX of the controls
 */
  renderCopyTemplateConfirmationDialog() {
    let rocheName = "";
    let title = "";
    if (this.state.copyAgreementConfirmationVisible && this.state.copyAgreementId !== null) {
      const templateAgreement = this.state.items.find(({ customerAgreementId }) => customerAgreementId === this.state.copyAgreementId);
      //get the record
      if (templateAgreement !== undefined) {
        rocheName = templateAgreement.rocheName;
        title = templateAgreement.title;
      }
    }
    if (this.state.copyAgreementConfirmationVisible) {
      return (
        <ModalDialogConfirmation 
            title="Create New Agreement using this as a template" 
            message={
              "You are about to copy the roche party, data clauses, data pairs & entitlements from " +
              ` "${title}" - "${rocheName}" as a new agreement`
            }
            visible={true}
            data={this.state.copyAgreementId}
            disableEscapeKeydown={true}
            disableBackdropClick={true}
            primaryButtonText="Ok"
            primaryButtonTooltip="Copy agreement & open to continue editing"
            secondaryButtonText="Cancel"
            secondaryButtonTooltip="Cancel this operation, no data will be changed"
            size="small"
            onPrimaryButtonClick={
              (ev,agreementId) => {this.setState(
                {
                  copyAgreementConfirmationVisible:false, 
                  copyAgreementId:null
                },
                () => this.createAgreementUsingTemplate(agreementId)
              )
            }}
                                                                
          onSecondaryButtonClick={() => {this.setState({
            copyAgreementConfirmationVisible:false, 
            copyAgreementId:null
          })}}
        />
      );
    }
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    //decide based on the space for this control needs to be squeezed
    let fontSize = 0.75;

    // width of the div below is set to 99% to avoid the "wobbles" that can happen when expanding a table row without a slight expansion room to the side.
    return (
      <div className="AgreementNavigation" style={{width:"99%", display:"flex", flexDirection:"column"}}>
        {this.renderSearchAndSort()}
        <br />
        {this.renderTable()}
        {
          this.state.items.length > 0 &&
          (this.state.error === null || this.state.error === undefined || this.state.error.length === 0) && 
          (
            <OwcPagination page={this.state.page} pageSize={this.state.rowsPerPage}
                hidePageSize={false} total={this.state.totalPages} pageSizeOptions={[10,20,50]}
                onPageChange={(ev) => this.handlePageChange(ev.detail)}
                style={{display:"flex", marginLeft: "auto"}}>
              <div slot="rows-per-page"><OwcTypography style={{fontSize:fontSize + "em"}}>Rows per Page</OwcTypography></div>
              <div ><OwcTypography style={{fontSize:fontSize + "em"}}> {this.state.page} of {this.state.totalPages} </OwcTypography></div>
            </OwcPagination>
          )
        }
        {this.renderCopyTemplateConfirmationDialog()}
      </div>
    );
  }
}

export default AgreementNavigation;
