import { OwcTab, OwcIconButton } from '@one/react';
import React from 'react';
import '../../styles.scss';

/**
 * Renders a read only list of data clauses
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class NavigationSidebar extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = { hidden: false };
    }

    handleTabClick = (event) => {
      if(event.target.value !== this.props.selectedTab){
        this.props.onTabChange(event.target.value);
      }
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      const iconString = (this.state.hidden === false ? "chevron_left" : "chevron_right");
      return (
        <div style={{display:"flex", justifyContent:"flex-start", height:"100%"}}>
          {
            this.state.hidden === false
            ?<div style={{display:"flex", flexDirection: "column"}}>
                {
                  this.props.tabs.map((tab) => (
                    <OwcTab 
                        key={`tab${tab.text}`} 
                        id={`tab${tab.text.replace(/(\s|&)/g,"-")}`}
                        value={tab.text}
                        selected={tab.text === this.props.selectedTab || tab.disabled}
                        disabled={tab.disabled}
                        variant={tab.text === this.props.selectedTab ? "secondary" : "primary"}
                        onClick={this.handleTabClick}
                        style={{ width:"9em" }}>
                      {tab.text}
                    </OwcTab>))
                }
              </div>
            : ""
          }
          <div style={{backgroundColor:"#EEE", paddingTop:"0.3em"}}>
            <OwcIconButton type="legacy" icon={iconString} onClick={() => this.setState({hidden:!this.state.hidden})} />
          </div>
        </div>
      );
    }
}

NavigationSidebar.defaultProps = {
  selectedTab: "",
  tabs: [],
  onTabChange:() => {},
}

export default NavigationSidebar;
