import { OwcIconButton, OwcIcon } from '@one/react';
import DelayedTooltip from "./DelayedTooltip.js";
import React from 'react';
import '../../styles.scss';

/**
 * Renders a text with a search lookup icon
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class TextWithLookup extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      const keyEnding = this.props.text + this.props.icon
      return (
        <div style={{display:"flex", alignItems:"center"}}
          key={"text" + keyEnding} id={"text" + keyEnding}>{this.props.text}
        {this.props.textTooltip?
            <DelayedTooltip key={"textToolTip" + keyEnding} anchor={"text" + keyEnding}>{this.props.textTooltip}</DelayedTooltip>
            :
            ""
        }
        {this.props.text?
          (
            <>
            <OwcIconButton style={{display: "inline-block"}}  key={"iconBtn" + keyEnding} id={"iconBtn" + keyEnding}
              elevated={!this.props.iconFlat}
              onClick={(ev) => this.props.onClick(ev)}
              >
                <OwcIcon type={this.props.iconType} name={this.props.icon} 
                  style={{fontSize:this.props.iconSize}} />
            </OwcIconButton>
            
            {this.props.iconTooltip?
              <DelayedTooltip key={"iconBtnToolTip" + keyEnding} anchor={"iconBtn" + keyEnding}>{this.props.iconTooltip}</DelayedTooltip>
              :
              ""
            }
            </>
          )
          :
          ""
        }
        </div>
      );

    }
}

TextWithLookup.defaultProps = {
  text: "",
  icon: "search",
  iconFlat: "true",
  iconType: "outlined",
  textTooltip: "",
  iconTooltip: "",
  iconSize: "0.75em",
}

export default TextWithLookup
