import React from 'react';
import {configData} from "./config.js";
import './styles.scss';
import {fetchSigned} from "./shared/Utilities.js"
import DataClauses from "./components/agreement/DataClauses.js";
import DelayedTooltip from "./components/general/DelayedTooltip.js";
import {
    OwcButton, OwcExpandableGroup, OwcExpandable,
    OwcInput, OwcIconButton, OwcAssistiveText,
    OwcTypography, OwcBadge
} from '@one/react';

/**
 * The interactive form for translating and structuring data agreement information
 * into pairs of terms of a data categroy and either a processing action or a data use
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class TranslateAndStructure extends React.Component {
    SUCCESSFULLY_SAVED = "Successfully Saved";

    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            dataClauses: [],
            selectedAgreementId: null,
            pairs: [],
            pairsToDelete: [],
            pairDeletionWarning: false,
            AgreementSelectionLoaded: false,
            submissionState: null,
            savingComplete: true,
        };
    }

  /** 
   * Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
            this.updateForm(this.props.customerAgreementId);
        }
    }

    /**
     * Runs one after construction after everything is initialised
     */
    componentDidMount() {
        this.updateForm(this.props.customerAgreementId, true);
    }

    /**
     * Simple delay function
     * @param {*} milliseconds The number of seconds to wait for
     * @returns A promise that will resolve after the delay
     */
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    /**
     * Forces the current rows to store to the database and refresh
     * @param {*} ev the event object
     */
    handleSubmitClick(ev) {
        if (this.state.selectedAgreementId !== null) {
            // save any unsaved pairs, unsaved pairs have -ve termPairNos
            this.saveTermPairs();
        }
    }

    /**
     * Forces the current rows to refresh from the database
     * @param {*} ev the event object
     */
    handleCancelClick(ev) {
        if (this.state.selectedAgreementId !== null) {
            this.setState(
                { pairsToDelete: [], submissionState: null, pairDeletionWarning: false },
                () => this.loadTermPairs(this.state.selectedAgreementId)
            );
            this.props.onUnsavedChangesChange(false);
        }
    }

    /**
     * Sets AgreementSelectionLoaded when Loaded
     */
    handleOnLoaded() {
        this.setState({ AgreementSelectionLoaded: true })
    }

    /** 
     * Handles the updating the form for a new agreement
     * @param {number} agreementId the selected agreement id
     */
    updateForm(agreementId, forceUpdate = false) {
        // if the agreementId is changed then
        if (forceUpdate || (agreementId !== this.state.selectedAgreementId)) {
            this.loadDataClauses(agreementId);
            this.setState(
                {selectedAgreementId: agreementId},
                this.loadTermPairs(agreementId));

            if (forceUpdate === false) {
                this.setState({ submissionState: null, pairDeletionWarning: false });
                this.props.onUnsavedChangesChange(false);
            }
        }
    }

    /**
     * Counts the number of records that need saving
     * @returns The number of altered records that need saving
     */
    countItemsToSave() {
        let itemsToSave = 0;
        for (const pair of this.state.pairs) {
            if (("changed" in pair)) {
                itemsToSave++;
            }
        }
        itemsToSave += this.state.pairsToDelete.length

        if (itemsToSave === 0 ) {
            if (this.state.submissionState !== null) {
                if (this.state.submissionState !== this.SUCCESSFULLY_SAVED) {
                    this.setState({submissionState: null});
                    this.props.onUnsavedChangesChange(false);
                }
            }
        }

        return itemsToSave;
    }

    /**
     * Checks if both the category and usage are empty
     * @param {*} pair the data for a pair
     * @returns True if both are empty otherwise false
     */
    bothCategoryAndUsageAreEmpty(pair) {
        const categoryIsEmpty = !pair.dataCategoryText || pair.dataCategoryText.trim() === "";
        const usageIsEmpty = !pair.dataUsageText || pair.dataUsageText.trim() === "";
        return categoryIsEmpty && usageIsEmpty;
    }

    /**
     * Validates the inputs on the form, and sets validation errors as approriate
     * @returns true if the form is valid otherwise false
     */
    validateInputs() {
        let isValid = true;
        for (const pair of this.state.pairs) {
            if (this.bothCategoryAndUsageAreEmpty(pair)) {
                isValid = false;
                this.setState({ submissionState: `Error: both category & usage for a pair cannot be empty.` });
            }
        }
        return isValid;
    }

    /**
     * Validates the inputs on the form, and sets validation errors as approriate
     * @returns true if the form is valid otherwise false
     */
    trimPairValues(pairs) {
        for (const pair of this.state.pairs) {
            if (pair.dataCategoryText) {
                pair.dataCategoryText = pair.dataCategoryText.trim();
            }
            if (pair.dataUsageText) {
                pair.dataUsageText = pair.dataUsageText.trim();
            }
        }
        return pairs;
    }

    /** 
     * Saves the current state term pairs
     */
    saveTermPairs() {
        let success = true;
        if (this.countItemsToSave() > 0) {
            if (this.validateInputs()) {
                this.setState({
                    submissionState: "Saving ...",
                    savingComplete: false
                });

                const pairs = this.trimPairValues(this.state.pairs.slice());
                this.updatePairs(pairs);

                this.setState({
                    pairsToDelete: []
                });
            }
        }
        return success;
    }

    /**
     * Call the API to update the term pairs
     * @param {*} pairs 
     */
    updatePairs(pairs) {
        const submitForm = () => {
            return fetchSigned(`${configData.TERMPAIRS_API_URL}${this.state.selectedAgreementId}/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"pairs": this.cleanUpOrdering(pairs)})
            })
            .then((response) => {
                console.log(response.status);
                let success = true;
                if (response.status === 200) // inserted successfully
                {
                    response.json().then((json) => {
                        console.log(`API Response: ${JSON.stringify(json)}`);
                        this.setState({ submissionState: this.SUCCESSFULLY_SAVED,
                                        pairDeletionWarning: false });
                        this.props.onUnsavedChangesChange(false);
                    }).catch((error) => {
                        console.error(error);
                        this.setState({ submissionState: `Error saving ${error}` });
                        success = false;
                    });
                } else {
                    success = false;
                    response.json().then((json) => {
                        console.log(`API Response: ${JSON.stringify(json)}`);
                        let errorMessage = "Error Saving"
                        if (json.errorText) {
                            errorMessage += json.errorText;
                            if (json.errorText.includes("ERROR: duplicate key value violates unique constraint")) {
                                errorMessage = "Error - Could not save a duplicate record";
                            }
                        }
                        this.setState({ submissionState: errorMessage });
                    }).catch((error) => {
                        console.error(error);
                        this.setState({ submissionState: `Error saving ${error}` });
                    });
                }
                return success;
            })
            .then((success) => {
                if (success === true) {
                    // Reload so the user sees what's current.
                    // but only on success, so the user doesn't lose their updates on error.
                    this.setState(
                        {savingComplete:true}, 
                        () => this.updateForm(this.state.selectedAgreementId, true));
                } else {
                    this.setState({savingComplete:true});
                }
            });
        };
        return submitForm();
    }

    /**
     * Loads term paires from the API
     * @param {number} agreementId The selected agreement ID
     */
    loadTermPairs(agreementId) {
        fetchSigned(`${configData.TERMPAIRS_API_URL}${agreementId}/`)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    this.setState({
                        pairs: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        error
                    });
                }
            );
    }

    /**
     * Loads data clauses from the API
     * @param {number} agreementId The selected agreement ID
     */
    loadDataClauses(agreementId) {
        fetchSigned(`${configData.CONTRACTS_API_URL}${agreementId}/`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        dataClauses: result[0].dataClauses
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        error
                    });
                }
            );
    }

    /**
     * Handle the add new pair button click
     * @param {*} pairType The type of pair to be added
     */
    handleAddPairsClick(pairType) {
        const newPairs = this.state.pairs.slice();
        let pairNo = -(newPairs.length + 1);
        const lowest = Math.min.apply(null, newPairs.map(x => x.termPairingNo)) -1;
        if (pairNo > lowest) {
            pairNo = lowest;
        }
        newPairs.push({
            customerAgreementId: this.state.selectedAgreementId,
            termPairingNo: pairNo,
            pairingType: pairType,
            dataCategoryText: null,
            dataUsageText: null,
            createdBy: this.props.userName,
            displayOrder: newPairs.length + 1,
            changed: true,
        })
        this.setState({ pairs: newPairs });
    }

    /**
     * Handles the remove pair buttons
     * @param {*} selectedAgreementId The selected agreement id
     * @param {*} selectedTermPairingNo the term pairing no to delete
     */
    handleRemovePairClick(selectedAgreementId, selectedTermPairingNo) {
        const newPairs = this.state.pairs.slice();
        const newPairstoDelete = this.state.pairsToDelete.slice();
        const index = newPairs.findIndex(({ customerAgreementId, termPairingNo }) => customerAgreementId === selectedAgreementId &&
            termPairingNo === selectedTermPairingNo);
        let newSubmissionState = this.state.submissionState;
        //get the index
        if (index > -1) {
            const pairToDelete = newPairs.splice(index, 1)[0]
            if (pairToDelete.termPairingNo > 0) {
                // this is already saved into the database
                newPairstoDelete.push(pairToDelete);
                newSubmissionState = "Unsaved changes, click Save Changes to save";

                if (pairToDelete.mappedToEntitlement === true) {
                    this.setState({ pairDeletionWarning: true });
                }

                this.props.onUnsavedChangesChange(true);
            }
        }
        this.setState({
            pairs: newPairs,
            pairsToDelete: newPairstoDelete,
            submissionState: newSubmissionState,
        });
    }

    /**
     * Handles the change to a text input
     * @param {*} value The text value of the list
     * @param {*} field The field that this list represents
     * @param {*} selectedAgreementId the agreement id
     * @param {*} selectedTermPairingNo the term pairing no for this pair
     */
    handleInputChange(value, field, selectedAgreementId, selectedTermPairingNo) {
        const newPairs = this.state.pairs.slice();
        const result = newPairs.find(
            ({ customerAgreementId, termPairingNo }) => 
                customerAgreementId === selectedAgreementId &&
                termPairingNo === selectedTermPairingNo
        );

        if (result[field] !== value) {
            result["changed"] = true;
            result[field] = value;
            this.setState({ pairs: newPairs, submissionState: "Unsaved changes, click Save Changes to save" });
            this.props.onUnsavedChangesChange(true);
        }
    }

    /**
     * Calculates how many rows the text controls need to display the text correctly
     *
     * @param {*} text the text to display
     * @param {*} cols the number of characters that can be displayed in one line
     */
    determineRowsToDisplay(text, cols) {
        let retVal = 1;
        if (text === null){
            return retVal;
        }

        //count the number of newlines
        const lines = text.split(/\r\n|\r|\n/)
        retVal = Math.max(1,lines.length);

        lines.forEach(line => {
            retVal += Math.floor(line.length / cols);
        })
        return retVal;
    }

    /**
     * Gets the pairDeletionWarning from the state
     * @returns The current pairDeletionWarning from the state
     */
    checkDeletionWarning() {
        return this.state.pairDeletionWarning;
    }

    /**
     * Sort provided pairs by display order.
     * @param {*} pairs pairs to sort
     * @returns the sorted pairs
     */
    sortPairsForDisplay(pairs) {
        return pairs.sort((a, b) => a.displayOrder - b.displayOrder);;
    }

    /**
     * Reset the pair orders to ensure they are ordered by consecutive integers from 1.
     * @param {*} pairs pairs to clean
     * @returns the pairs with clean order
     */
    cleanUpOrdering(pairs) {
        pairs = this.sortPairsForDisplay(pairs);
        let order = 1;
        pairs.forEach(pair => {
            pair.displayOrder = order++;
        });
        return pairs;
    }
    
    /**
     * Handles the clicks on the sorting arrows.
     * @param {*} selectedAgreementId agreement Id of the pair
     * @param {*} selectedTermPairingNo id of the selected pair
     * @param {*} direction how the pair is asked to move, either "up" or "down"
     */
    handlePairMove(selectedAgreementId, selectedTermPairingNo, direction) {
        const pairs = this.state.pairs.slice();
        this.cleanUpOrdering(pairs); // To catch item deletions that could leave gaps in the ordering.

        let positionShift = 0;
        if (direction === "up") {
            positionShift = -1; // Moving up means reducing the displayOrder
        } else if (direction === "down") {
            positionShift = 1;
        }

        const selectedPair = pairs.find(({ customerAgreementId, termPairingNo }
            ) => customerAgreementId === selectedAgreementId && termPairingNo === selectedTermPairingNo
        );

        const pairToSwapWith = pairs.find(({ customerAgreementId, displayOrder }
            ) => customerAgreementId === selectedAgreementId 
              && displayOrder === selectedPair.displayOrder + positionShift);        
        
        if (pairToSwapWith && positionShift !== 0) {
            selectedPair.displayOrder += positionShift;
            pairToSwapWith.displayOrder -= positionShift;
            selectedPair.changed = true;
            pairToSwapWith.changed = true;

            this.setState({ 
                pairs: this.sortPairsForDisplay(pairs), 
                submissionState: "Unsaved changes, click Save Changes to save" });
            this.props.onUnsavedChangesChange(true);
        }
    }

    /**
     * Checks if a given pair is at the top or the bottom of the list.
     * @param {*} pair the pair to check
     * @param {*} edge either "top" or "bottom"
     * @returns true if at the edge, false otherwise.
     */
    isPairAtEdge(pair, edge) {
        const pairs = this.state.pairs.slice();
        this.cleanUpOrdering(pairs);

        let edgePair = null;
        if (edge === "top") {
            edgePair = pairs[0];
        } else if (edge === "bottom") {
            edgePair = pairs[pairs.length - 1];
        }

        if (edgePair && pair.displayOrder === edgePair.displayOrder) {
            return true;
        }
        return false;
    }

    /**
     * Enders the up and down ordering buttons
     * @param {*} pair The pair data being rendered
     * @returns the JSX of the controls
     */
    renderOrderingButtons(pair){
      return (
        <div key={"pairDivOrderingBtn" + pair.customerAgreementId + "-" + pair.termPairingNo} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap"}}>
          <div  key={"pairDivOrderingBtnInnerDiv1-" + pair.customerAgreementId + "-" + pair.termPairingNo}>
            <OwcIconButton
              id={"pairMoveUpBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
              key={"pairMoveUpBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
              icon="chevron_up" style={{ display: "inline-block", verticalAlign: "top" }}
              onclick={() => this.handlePairMove(pair.customerAgreementId, pair.termPairingNo, "up")}
              disabled={this.isPairAtEdge(pair, "top")}/>
            <DelayedTooltip 
                key={"pairMoveUpBtnToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                anchor={"pairMoveUpBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                placement="right">
              Move this {pair.pairingType} up
            </DelayedTooltip>
          </div>
          <div key={"pairDivOrderingBtnInnerDiv2-" + pair.customerAgreementId + "-" + pair.termPairingNo}>
            <OwcIconButton 
                id={"pairMoveDownBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                key={"pairMoveDownBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                icon="chevron_down" style={{ display: "inline-block", verticalAlign: "top" }}
                onclick={() => this.handlePairMove(pair.customerAgreementId, pair.termPairingNo, "down")} 
                disabled={this.isPairAtEdge(pair, "bottom")}/>
            <DelayedTooltip 
                key={"pairMoveDownBtnToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                anchor={"pairMoveDownBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                placement="right">
              Move this {pair.pairingType} down
            </DelayedTooltip>
          </div>
        </div>
      )
    }

    /**
     * Renders a list of pairs
     * @returns The JSX of the controls
     */
    renderPairs() {
      return (
        <div>
          {this.state.pairs.map((pair) => (
            <div key={"pairDiv" + pair.customerAgreementId + "-" + pair.termPairingNo}  
                style={{ 
                  display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItem:"flex-start",
                  padding:"0 10px 10px 0"
                }}>
              <b style={{ width: "10%", marginRight: "1em", display: "inline-block", verticalAlign: "top" }}>
                {pair.pairingType}
              </b>
              <OwcInput style={{ display: "inline-block", marginRight: "1%", verticalAlign: "top" }}
                  id={"pairdataCategory" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  key={"pairdataCategory" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  label="Data Category" value={pair.dataCategoryText}
                  cols="20" rows={this.determineRowsToDisplay(pair.dataCategoryText,20)}
                  onValueChange={(ev) => this.handleInputChange(ev.detail, "dataCategoryText", pair.customerAgreementId, pair.termPairingNo)}
                  validation-mode="completion"
                  minlength={1} maxlength={255}  
                  type="textarea" required no-clear-icon resizable="false">
                <OwcAssistiveText>
                  Required (in english)
                </OwcAssistiveText>
              </OwcInput>
              <DelayedTooltip key={"pairdataCategoryToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  anchor={"pairdataCategory" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  placement="bottom">
                The type of data defined in the agreement:  e.g. “service data”, “patient data”, “sensitive data”, “non-personal data”, ...
              </DelayedTooltip>
              <OwcInput style={{ display: "inline-block", marginRight: "1%", verticalAlign: "top" }}
                  id={"pairdataUsage" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  key={"pairdataUsage" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  label={pair.pairingType} value={pair.dataUsageText}
                  cols="55" rows={this.determineRowsToDisplay(pair.dataUsageText,55)}
                  onValueChange={(ev) => this.handleInputChange(ev.detail, "dataUsageText", pair.customerAgreementId, pair.termPairingNo)}
                  required validation-mode="completion"
                  minlength={1} maxlength={600}
                  type="textarea"
                  no-clear-icon resizable={false}>
                <OwcAssistiveText>
                  Required (in english)
                </OwcAssistiveText>
              </OwcInput>
              <DelayedTooltip key={"pairdataUsageToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  anchor={"pairdataUsage" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  placement="bottom">
                {pair.pairingType === configData.ENTITLEMENT_DATA_USE ?
                `data use: “improve Roche’s services”, “prepare for pandemic”, “monitor field operations”
                                        ... as defined in the agreement` :
                `data processing action: “collect”, “use”, “store”, “process”, “edit”, ... as defined in the agreement`}
              </DelayedTooltip>
              {this.renderOrderingButtons(pair)}
              <OwcIconButton id={"pairCancelBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  key={"pairCancelBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  icon="circle_clear" style={{ display: "inline-block", verticalAlign: "top" }}
                  onclick={() => this.handleRemovePairClick(pair.customerAgreementId, pair.termPairingNo)} />
              <DelayedTooltip key={"pairCancelBtnToolTip" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  anchor={"pairCancelBtn" + pair.customerAgreementId + "-" + pair.termPairingNo}
                  placement="right">Remove this {pair.pairingType}
              </DelayedTooltip>
              <div style={{ display: "flex"}}>
                {(pair.entitlementCount===undefined || pair.entitlementCount===0)?
                (
                  <OwcBadge key={"pairEntitlementCount" + pair.customerAgreementId + "-" + pair.termPairingNo} 
                      status="info" 
                      style={{margin: '5px 20px 10px 0', verticalAlign: 'top', height:'fit-content'}}>
                    no mapped entitlement
                  </OwcBadge>
                )
                :(
                  <OwcBadge key={"pairEntitlementCount" + pair.customerAgreementId + "-" + pair.termPairingNo} 
                      disable-ripple round
                      status="active"
                      style={{
                        margin: '5px 20px 10px 0', verticalAlign: 'top', height:'fit-content',
                        backgroundColor:'var(--one-color-blue-600)'
                      }}>
                    {pair.entitlementCount} mapped entitlement{pair.entitlementCount>1?"s":""}
                  </OwcBadge>
                )}
              </div>
            </div>
          ))}
        </div>
      )
    }

    /**
     * Renders the Add pairs buttons
     * @returns The JSX of the controls
     */
    renderAddPairsButtons() {
        return (
            <div>
                <OwcButton style={{ width: "fit-content", display: "inline-block" }}
                           onclick={() => this.handleAddPairsClick(configData.ENTITLEMENT_PROCESSING_ACTION)}>
                    Add data processing action
                </OwcButton>
                <OwcButton style={{ width: "fit-content", display: "inline-block", marginLeft: "10px" }}
                           onclick={() => this.handleAddPairsClick(configData.ENTITLEMENT_DATA_USE)}>
                    Add data use
                </OwcButton>
            </div>
        )
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
        let messageColour = "black";
        if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
            messageColour = "red";
        };

        if (this.props.customerAgreementId === null) {
            return (
                <OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an agreement in "Capture Agreement" first</OwcTypography>
            );
        } else {
            return (
                <div className="TranslateAndStructure">
                    <OwcExpandableGroup multiple>
                        <OwcExpandable key="dataClauses" variant="standard" round expanded >
                            <span key="dataClausesTitle" slot="title">Agreement Data Clauses</span>
                            <span key="dataClausesContent" slot="content">
                                {this.state.selectedAgreementId === null ? "" : <DataClauses dataClauses={this.state.dataClauses}/>}
                                {this.state.pairs.length === 0 ? "" : this.renderPairs()}
                                {this.state.selectedAgreementId === null ? "" : this.renderAddPairsButtons()}                                
                            </span>
                        </OwcExpandable>
                    </OwcExpandableGroup>
                    <div style={{ display:"flex", flexDirection:"column" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td align="left">
                              <OwcButton elevated style={{ width: "fit-content" }}
                                  onclick={() => this.handleCancelClick()}>
                                Clear Unsaved Changes
                              </OwcButton>
                            </td>
                            <td align="right">
                              <OwcButton style={{ width: "fit-content" }}
                                  onclick={() => this.handleSubmitClick()} 
                                  disabled={
                                    ((this.state.submissionState === "Saving ...") || (this.state.savingComplete === false) || (this.countItemsToSave() === 0))
                                  }>
                                {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                              </OwcButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
                        {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
                        <br/>
                        {this.checkDeletionWarning()
                          ?(<OwcTypography style={{"color":"orange"}} variant="title6">
                             Warning! Saving would remove the item leaving the entitlement unmapped. Clear changes if you disagree with this change.
                           </OwcTypography>) 
                          :""
                        }
                      </OwcTypography>
                    </div>
                </div>
            );
        }
    }
}

export default TranslateAndStructure;
