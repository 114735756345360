import { OwcIconButton, OwcIcon } from '@one/react';
import DelayedTooltip from '../general/DelayedTooltip';
import React from 'react';
import '../../styles.scss';

/**
 * Renders an icon button to display device moves
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class DeviceMoveIcon extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      const keyEnding = this.props.id
      return (
        <OwcIconButton style={{display: "inline-block"}}  
            key={"DeviceMoveIcon" + keyEnding} id={"DeviceMoveIcon" + keyEnding}
            elevated={!this.props.iconFlat} >
          <OwcIcon style={{fontSize:this.props.iconSize}}>
            <svg version="1.1" width="18" height="18" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
              <g>
                <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                <path d="M8287.7,4180.6c-365.9-80.4-655.4-321.7-852.4-711.7C7381,3362.4,5617.9-789,5473.2-1150.9c-6-14.1,52.3,16.1,126.7,64.3c217.1,144.7,470.4,233.2,792.1,277.4l168.9,24.1l788,1869.6C7783.1,2114,8163,3006.5,8195.2,3070.9c60.3,128.7,176.9,247.3,257.3,265.4c28.2,6,72.4,14.1,98.5,20.1c90.5,18.1,361.9-32.2,615.2-114.6c291.5-96.5,402.1-102.5,526.7-32.2c46.2,26.1,112.6,86.4,146.8,132.7c54.3,80.4,60.3,102.5,60.3,243.3c0,138.7-6,164.9-56.3,237.2c-70.4,100.5-185,162.8-470.4,255.3C8971.2,4206.7,8579.2,4244.9,8287.7,4180.6z"/><path d="M2383.3,3945.4c-221.1-498.6-1560-3697-1560-3723.2c0-22.1,40.2-52.3,112.6-84.4c466.4-205.1,3689-1556,3713.1-1556c18.1,0,46.2,20.1,62.3,46.2c64.3,98.5,1600.2,3771.4,1590.2,3799.6c-14.1,34.2,12.1,22.1-693.6,319.7c-315.6,132.7-579,245.3-585,249.3c-6,4-154.8-333.7-329.7-747.9c-193-454.3-333.7-761.9-353.8-774c-28.1-14.1-170.9,38.2-595.1,217.1c-307.6,128.7-570.9,243.2-583,253.3c-14.1,10-26.1,38.2-26.1,62.3c0,22.1,140.7,371.9,311.6,776c168.9,404.1,303.6,739.8,295.5,745.8c-34.2,32.2-1222.3,520.7-1264.5,520.7C2439.6,4049.9,2419.5,4029.8,2383.3,3945.4z"/><path d="M330.7-370.8c-44.2-20.1-100.5-58.3-124.6-84.4C49.3-626.2,69.4-947.8,246.3-1082.5C322.7-1138.8,4470-2891.8,4606.7-2924c154.8-36.2,146.7-50.3,140.7,219.1c-6,221.1,2,305.6,62.3,589c2,8-936.8,414.1-2086.7,898.6C819.2-413.1,622.2-332.7,521.7-332.7C457.4-332.7,379-348.7,330.7-370.8z"/><path d="M6432.1-1319.7c-309.6-36.2-568.9-164.9-798.1-394c-424.2-426.2-504.6-1105.7-193-1630.4c102.5-172.9,341.8-398,522.7-492.5c394-205.1,862.4-207.1,1258.5,0c185,94.5,440.3,345.8,542.8,528.7c114.6,211.1,162.8,398,164.8,643.3c0,241.2-28.1,371.9-120.6,572.9C7566-1561,7013.1-1251.4,6432.1-1319.7z M6816.1-2216.4c398.1-203,341.8-774-90.5-910.7c-184.9-58.3-420.1,24.1-534.7,191c-142.7,207.1-120.6,454.4,56.3,631.3c106.5,106.5,185,136.7,347.8,140.7C6679.4-2162.1,6731.7-2176.2,6816.1-2216.4z"/>
                </g>
              </g>
            </svg>
          </OwcIcon>
          {this.props.iconTooltip
            ?<DelayedTooltip key={"DeviceMoveIconToolTip" + keyEnding} anchor={"DeviceMoveIcon" + keyEnding}>{this.props.iconTooltip}</DelayedTooltip>
            : ""
          }
        </OwcIconButton>
      );
    }
}

DeviceMoveIcon.defaultProps = {
  id: Math.random(),
  text: "",
  iconTooltip: "Device Move Signal",
  iconFlat: "true",
}

export default DeviceMoveIcon
