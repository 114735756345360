import { OwcTypography } from '@one/react';
import React from 'react';
import '../../styles.scss';

import {configData} from "../../config.js";
import {fetchSigned, getDescriptionForRefDataId, getIdForRefData} from "../../shared/Utilities.js"

/**
 * Renders the List of user preferences
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class PreferenceList extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      userPreferences: {
        userName:this.props.userName,
      },
      affiliateList: [],
      submissionState: "",
    };
  }

  
  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredAffiliateList = result.filter(value =>
            value.type === "Affiliate"
          );
          this.setState({ affiliateList: filteredAffiliateList });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (errorMsg) => {
          this.setState({
            error: "Error returning referenece list" + errorMsg
          });
        }
      );
      this.loadUserPreferences();
  }

  /**
   * When the props or state changes
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.refreshUserPreferences !== prevProps.refreshUserPreferences) {
      this.loadUserPreferences();
    }
  }

  /**
   * Loads the user preferences for the this.props.userName
   */
  loadUserPreferences() {
    if (this.props.userName !== null) 
    {
      fetchSigned(configData.USER_API_URL + `preferences/${this.props.userName}/`)
      .then(res => res.json())
      .then(
        (result) => {
          console.log("User Preferences Response");
          console.log(result);
          if (result.length > 0) {
            this.setState({userPreferences:result[0]});
          }
        },    
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      );
    }
  }

  /**
   * Handles changes to the Affiliate default
   * Looking up the releated ref list id and setting this into the state
   */
  handleAffiliateChange(ev) {
    const id = getIdForRefData(ev.target.value, this.state.affiliateList);
    if (id !== this.state.userPreferences.affiliateRefListId) {
      if (id !== null || ev.target.value === "All") {
        const newUserPreferences = {...this.state.userPreferences};
        newUserPreferences.affiliateRefListId = id;
        this.setState({ userPreferences: newUserPreferences }, () => this.saveChanges());
      }
    }
  }

  /**
   * Saves user preference changes in the state to the database
   */
  saveChanges() {
    if (this.state.userPreferences.userName !== null) {
      const submissionData = [{
        userName: this.state.userPreferences.userName,
        affiliateRefListId:  this.state.userPreferences.affiliateRefListId,
        }
      ];
  
      console.log("Submitting Prefernces...", submissionData);
  
      this.setState({ submissionState: "Saving ..." });
  
      fetchSigned(configData.USER_API_URL + "preferences/", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        })
          .then((response) => {
            console.log(response);
            if (response.status === 201) // inserted successfully
            {
              response.json().then((json) => {
                this.setState({ submissionState: "Preferences successfully saved" })
                this.props.onPreferencesChanged();
                console.log("API Response" + JSON.stringify(json));
              }).catch((error) => {
                this.setState({ submissionState: "Error saving preferences " + error });
                console.error(error);
              });
            } else {
              response.json().then((json) => {
                console.log("API Response" + JSON.stringify(json));
                this.setState({ submissionState: "Error saving saving preferences " + json.errorText });
              }).catch((error) => {
                this.setState({ submissionState: "Error saving saving preferences " + error })
                console.error(error);
              });
            }
          });
      };
  }


  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let affiliateText = getDescriptionForRefDataId(this.state.userPreferences.affiliateRefListId, this.state.affiliateList);
    if (affiliateText === null) {
      affiliateText = "All";
    }    
    if (this.state.userPreferences.affiliateRefListId === undefined) {
      affiliateText = "";
    }
    return (
      <div style={{display:"flex", flexDirection:"column"}}>
        <div style={{display:"flex", flexDirection:"row", gap:"1em", alignItems:"baseline"}}>
          <OwcTypography>Affiliate:</OwcTypography>
          <select style={{maxWidth:"25em"}}
            value={affiliateText}
            onChange={(ev) => this.handleAffiliateChange(ev)}> 
          {this.state.userPreferences.affiliateRefListId === undefined?
            (<option key={"NavAffiliateBlank"}></option>)
          :
            ""
          }
          <option key={"NavAffiliateAll"}>All</option>
          {this.state.affiliateList.map(item => (
            <option key={"NavAffiliate" + item.refListId}>
              {item.isActive?item.description:item.description + configData.REFDATA_DEPRECATED}
            </option>
          ))}
          </select>
        </div>
      </div>
    );

  }
}

PreferenceList.defaultProps = {
  onPreferencesChanged: ()=>{},
  refreshUserPreferences:"",
}

export default PreferenceList
