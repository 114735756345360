import { OwcModalDialog, OwcTypography, OwcIcon, OwcButton } from '@one/react';
import React from 'react';
import '../../styles.scss';
import PreferenceList from './PreferenceList';

/**
 * Renders the First Time Welcome Overlay
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class FirstTimeWelcome extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      closeButtonEnabled: false,
    };
  }


  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    return (
      <OwcModalDialog
          size="large"
          visible={this.props.visible} 
          disableBackdropClick={this.props.disableBackdropClick} 
          disableEscapeKeydown={this.props.disableEscapeKeydown} 
          disableScrollLock={this.props.disableScrollLock} 
          onVisibleChange={(ev)=>this.props.onVisibleChange(ev)}>
        <div style={{marginLeft:"0.5em", marginRight:"0.5em"}} >
          <div slot="header" style={{ display: 'flex', flexDirection:"row", alignItems: 'center'}}>
            <OwcTypography variant="title5">Data Entitlements</OwcTypography>
          </div>
          <div>
            <OwcTypography>Welcome to the Data Entitlements Domain, {this.props.userName}</OwcTypography>
            <OwcTypography>This appears to be your first use of this system for capturing and 
            curating data entitlements from customer agreements.</OwcTypography>
            <br/>
            <OwcTypography>Please set your default preferences (these can be changed later by clicking on the user icon &nbsp;
              <OwcIcon name="user" type="outlined"></OwcIcon> &nbsp;
              in the toolbar):
            </OwcTypography>
            <div style={{marginLeft:"5em", marginTop:"0.5em"}}>
            <PreferenceList userName={this.props.userName} onPreferencesChanged={()=>{this.setState({closeButtonEnabled:true})}}></PreferenceList>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection:"column", alignItems: 'flex-end', marginBottom:"0.5em"}}>
            <OwcButton style={{alignSelf:"flex-end"}} disabled={this.state.closeButtonEnabled === false}
              onClick={()=> {this.props.onPreferencesChanged()}}>
              Close
            </OwcButton>
          </div>
        </div>
      </OwcModalDialog>
    );

  }
}

FirstTimeWelcome.defaultProps = {
  visible: true,
  disableBackdropClick: false,
  disableEscapeKeydown: false,
  disableScrollLock: false,
  onVisibleChange:() => {},
  onPreferencesChanged:() => {},
}

export default FirstTimeWelcome
