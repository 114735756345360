import { OwcModalDialog, OwcButton, OwcTypography, OwcIcon } from '@one/react';
import DelayedTooltip from "./DelayedTooltip.js";
import React from 'react';
import '../../styles.scss';

/**
 * Renders a text with a search lookup icon
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class ModalDialogConfirmation extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    return (
      <OwcModalDialog 
          visible={this.props.visible} 
          size={this.props.size}
          disableBackdropClick={this.props.disableBackdropClick} 
          disableEscapeKeydown={this.props.disableEscapeKeydown} 
          disableScrollLock={this.props.disableScrollLock} 
          onVisibleChange={(ev)=>this.props.onVisibleChange(ev)} >
        <div slot="header" style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          <OwcIcon name={this.props.icon} style={{ marginRight: "0.5em", color: 'inherit' }} flat={this.props.iconFlat}></OwcIcon>
          <OwcTypography>{this.props.title}</OwcTypography>
        </div>
        <div slot="footer" style={{ justifyContent: 'flex-end', padding: '5px' }}>
          <OwcTypography>{this.props.message}</OwcTypography>
        </div>
        <div slot="footer" style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px' }}>
          {this.props.secondaryButtonText?
            (
              <>
              <OwcButton style={{ marginLeft: "2em" }} 
                id={"modalDialogConfirmationBtn2"} 
                variant="secondary" 
                flat
                onClick={(ev) => this.props.onSecondaryButtonClick(ev, this.props.data)}
              >{this.props.secondaryButtonText}</OwcButton>
              {this.props.secondaryButtonTooltip?
                <DelayedTooltip key={"modalDialogConfirmationBtn2Tooltip"} 
                  anchor={"modalDialogConfirmationBtn2"}
                >{this.props.secondaryButtonTooltip}</DelayedTooltip>
                :
                ""
              }
              </>
            )
          :
          ""
          }

          {this.props.primaryButtonText?
            (
              <>
              <OwcButton style={{ marginLeft: "2em" }} 
                id={"modalDialogConfirmationBtn1"} 
                flat
                onClick={(ev) => this.props.onPrimaryButtonClick(ev, this.props.data)}
              >{this.props.primaryButtonText}</OwcButton>
              {this.props.primaryButtonTooltip?
                <DelayedTooltip key={"modalDialogConfirmationBtn1Tooltip"} 
                    anchor={"modalDialogConfirmationBtn1"}>
                  {this.props.primaryButtonTooltip}
                </DelayedTooltip>
                :
                ""
              }
              </>
            )
          :
          ""
          }
        </div>
      </OwcModalDialog>
    );
  }
}

ModalDialogConfirmation.defaultProps = {
  title: "",
  message: "",
  icon: "circle_info",
  iconFlat: "true",
  data: null,
  textTooltip: "",
  primaryButtonText: "Ok",
  primaryButtonTooltip: "",
  secondaryButtonText: "Cancel",
  secondaryButtonTooltip: "",
  visible: true,
  disableBackdropClick: false,
  disableEscapeKeydown: false,
  disableScrollLock: false,
  denseContent: false,
  size: "sm",
  onVisibleChange:() => {},
}

export default ModalDialogConfirmation
