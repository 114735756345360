import { OwcIcon } from '@one/react';
import DelayedTooltip from '../general/DelayedTooltip';
import React from 'react';
import '../../styles.scss';

/**
 * Renders an icon button to display a shield
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class ProtectedIcon extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      const keyEnding = this.props.id
      return (
          <OwcIcon style={{fontSize:this.props.iconSize}}  key={"ProtectedIcon" + keyEnding} id={"ProtectedIcon" + keyEnding}>
            <svg fill="var(--one-color-interaction-default-neutral-3)" version="1.1" width="18" height="18" x="0px" y="0px" viewBox="0 0 476 580" enableBackground="new 0 0 1000 1000">
            <g>
              <path d="M442.138,11.508c-1.107-4.625-4.342-8.45-8.718-10.312s-9.375-1.537-13.476,0.875c-53.767,31.626-120.569,31.628-174.34,0
                c-4.692-2.762-10.516-2.762-15.21,0c-53.768,31.627-120.571,31.627-174.339,0c-4.099-2.412-9.099-2.734-13.475-0.875
                c-4.376,1.861-7.611,5.687-8.718,10.312C-4.815,173.094,55.859,342.213,188.436,442.359l40.522,30.61
                c2.675,2.021,5.857,3.031,9.041,3.031c3.183,0,6.367-1.011,9.042-3.031l40.522-30.61
                C420.141,342.213,480.815,173.094,442.138,11.508z M269.482,418.421L238,442.201l-31.481-23.78
                C88.624,329.366,31.987,181.539,59.004,37.407C115.969,63.545,182.278,61.805,238,32.185c55.724,29.62,122.03,31.361,178.996,5.222
                C444.013,181.539,387.376,329.366,269.482,418.421z"/>
              <path d="M397.194,71.145c-2.409-1.708-5.451-2.265-8.304-1.519c-18.303,4.766-37.182,7.183-56.114,7.183
                c-30.958,0-62.325-6.672-90.712-19.296c-2.588-1.15-5.54-1.15-8.127,0c-28.386,12.624-59.753,19.296-90.711,19.296
                c-18.933,0-37.813-2.417-56.115-7.183c-2.857-0.745-5.896-0.188-8.303,1.519s-3.938,4.391-4.183,7.332
                c-4.871,58.737,4.658,118.513,27.557,172.864c25.044,59.444,65.5,111.425,116.995,150.323l12.797,9.666
                c1.783,1.347,3.905,2.021,6.027,2.021s4.244-0.674,6.027-2.021l12.796-9.666c51.496-38.899,91.952-90.881,116.996-150.323
                c22.898-54.352,32.428-114.127,27.557-172.864C401.132,75.535,399.602,72.852,397.194,71.145z M355.388,243.575
                c-23.678,56.202-61.93,105.351-110.619,142.13L238,390.819l-6.77-5.113c-48.688-36.778-86.94-85.926-110.619-142.13
                c-20.146-47.818-29.348-100.077-26.819-151.852c16.242,3.377,32.819,5.085,49.433,5.085c32.266,0,64.934-6.648,94.775-19.259
                c29.842,12.61,62.51,19.259,94.775,19.259c16.613,0,33.19-1.708,49.433-5.085C384.737,143.498,375.535,195.757,355.388,243.575z"/>
            </g>
            </svg>
            <DelayedTooltip key={"ProtectedIconTooltip" + keyEnding} anchor={"ProtectedIcon" + keyEnding} placement="left">
                      {this.props.iconTooltip}
            </DelayedTooltip>
          </OwcIcon>
      );
    }
}

ProtectedIcon.defaultProps = {
  id: Math.random(),
  text: "",
  iconTooltip: "Device mapping protected from unconfirmation",
}

export default ProtectedIcon
